import crm from "./crm";

const tarefasService = {
  index: (filtros = null) => {
    return crm.get("/tarefa", { params: {filtros: filtros} });
  },
  count: (filtros = null) => {
    return crm.get("/tarefa/count", { params: {filtros: filtros} });
  },
  deletar: (id) => {
    return crm.delete(`/tarefa/${id}`);
  },
  store: (dados) => {
    let uri = "/tarefa";
    return crm["post"].call(crm, uri, { tarefa: dados });
  },
  update: (id, dados) => {
    let uri = "/tarefa/" + id;
    return crm["put"].call(crm, uri, { tarefa: dados });
  },
  deletarPorUsuarioEHorario: (id_usuario, data, id_ticket) => {
    return crm.delete(`tarefa/vistoriador/${id_usuario}/data/${data}/ticket/${id_ticket}`)
  },
  salvarStep: (step) => {
    return crm.put(`tarefa/step/${step}`)
  }
};

export default tarefasService;
