import crm from "./crm";

const ArquivoGaleiraService = {
    buscar: (filtros) => {
        return crm.get("/arquivo-galeria", { params: filtros });
    },
    salvar: (arquivos) => {
        return crm.post("/arquivo-galeria", arquivos);
    },
    excluir: (idArquivo) => {
        return crm.delete(`/arquivo-galeria/${idArquivo}`);
    },
};

export default ArquivoGaleiraService;
