import crm from './crm';

const relatorioService = {
    relatorioGeral: (filtros = null) => {
        return crm.get('/relatorio/ticket/geral', {params: { filtros }, responseType: 'blob'});
    },
    listRelatoriosDiversos: ()=>{
        return crm.get('/relatorio/diversos');
    },
    buscarFiltrosRelatorio: (idRelatorio) => {
        return crm.get(`/relatorio/${idRelatorio}/diversos/filtros`)
    },
    pesquisarDiversos: (filtros) => {
        return crm.get('/relatorio/diversos/pesquisar', { params: filtros })
    },
    exportarDiversos: (filtros) => {
        return crm.get('/relatorio/diversos/exportar', { params: filtros })
    },
};

export default relatorioService;
