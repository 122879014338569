import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import { trackPromise } from "react-promise-tracker";
import ticketService from "../../../../../../../services/TicketService";
import Swal from "sweetalert2";
import { Accordion, Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { MensagemTexto } from "./MensagemTexto";
import { WhatsappComponent } from "./WhatsappComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { EmailComponent } from "./EmailComponent";
import { VoipComponent } from "./VoipComponent";

export function MensagensBody({ atendimento, isOpen }) {
    const [listaMensagens, setListaMensagens] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        if (isOpen && !listaMensagens?.length) {
            handleLoadMore();
        }
    }, [isOpen]);

    function handleLoadMore() {
        ticketService
            .buscaAtendimentoMensagens(atendimento.id, parseInt(page) + 1)
            .then((response) => {
                let dados = response?.data;
                setListaMensagens((current) => {
                    return [...dados?.conteudos, ...current];
                });
                setHasMore(dados?.hasMore);
                setPage(dados?.currentPage);
            })
            .catch((erro) => {
                Swal.fire(
                    "Ooops... Ocorreu um erro ao carregar as mensagens.",
                    "",
                    "error"
                );
            });
    }

    function handleTrataMensagensWhatsapp() {
        if (listaMensagens) {
            let listIterar = Array.from(listaMensagens.values())
                .filter((msg) => msg && msg?.detalhes)
                .reverse();
            let date = moment().format("DD/MM/YYYY");
            return listIterar.map((msg, indice) => {
                let isLast = indice === listIterar.length - 1;
                let separator = false;
                let separatorLast = false;

                let msg_date = moment(msg.criado_em).format("DD/MM/YYYY");
                if (date !== msg_date) {
                    separator = (
                        <div className="separador-mensagem">{msg_date}</div>
                    );
                    date = msg_date;
                }
                if (isLast) {
                    separatorLast = (
                        <div className="separador-mensagem">{msg_date}</div>
                    );
                }
                return (
                    <Fragment key={msg.id}>
                        {separator}
                        <WhatsappComponent
                            atendimento={atendimento}
                            conteudo={msg}
                        />
                        {separatorLast}
                    </Fragment>
                );
            });
        }
    }

    function handleTrataMensagensTexto() {
        if (listaMensagens) {
            let listIterar = Array.from(listaMensagens.values())
                .filter((msg) => msg && msg?.detalhes)
                .reverse();
            let date = moment().format("DD/MM/YYYY");
            return listIterar.map((msg, indice) => {
                let isLast = indice === listIterar.length - 1;
                let separator = false;
                let separatorLast = false;

                let msg_date = moment(msg.criado_em).format("DD/MM/YYYY");
                if (date !== msg_date) {
                    separator = (
                        <div className="separador-mensagem">{msg_date}</div>
                    );
                    date = msg_date;
                }
                if (isLast) {
                    separatorLast = (
                        <div className="separador-mensagem">{msg_date}</div>
                    );
                }
                return (
                    <Fragment key={msg.id}>
                        {separator}
                        <MensagemTexto
                            atendimento={atendimento}
                            conteudo={msg}
                        />
                        {separatorLast}
                    </Fragment>
                );
            });
        }
    }

    function handleTrataMensagensEmail() {
        if (listaMensagens) {
            let listIterar = Array.from(listaMensagens.values())
                .filter((msg) => msg && msg?.detalhes)
                .reverse();
            let date = moment().format("DD/MM/YYYY");
            return listIterar.map((msg, indice) => {
                return (
                    <Fragment key={msg.id}>
                        <EmailComponent conteudo={msg} />                     
                    </Fragment>
                );
            });
        }
    }

    const verificarTipoMensagens = () => {
        switch (atendimento?.meio_comunicacao?.slug) {
            case "whatsapp":
                return handleTrataMensagensWhatsapp();
            case "sms":
            case "chatbot":
                return handleTrataMensagensTexto();
            case "email":
                return handleTrataMensagensEmail();
            default:
                return <></>;
        }
    };

    return (
        <div className="mensagens-body">
            {listaMensagens && listaMensagens?.length ? (
                atendimento?.meio_comunicacao?.slug === "voip" ? (
                    <VoipComponent
                        conteudo={listaMensagens}
                        atendimento={atendimento}
                    />
                ) : (
                    <>
                        <div
                            className="mensagens-body-texto"
                            id="scrollableDiv"
                        >
                            <InfiniteScroll
                                useWindow={true}
                                dataLength={listaMensagens?.length}
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                                next={handleLoadMore}
                                hasMore={hasMore}
                                inverse={true}
                                loader={
                                    <Spinner
                                        animation="border"
                                        style={{ color: "#265d9c" }}
                                    />
                                }
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Fim das Mensagens</b>
                                    </p>
                                }
                                refreshFunction={() => {}}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={10}
                                scrollableTarget="scrollableDiv"
                            >
                                {verificarTipoMensagens()}
                            </InfiniteScroll>
                        </div>
                    </>
                )
            ) : (
                <Alert variant="warning">
                    Não possui nenhuma mensagem ainda
                </Alert>
            )}
        </div>
    );
}
