import React, {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import moment from "moment";
import "moment/min/locales";
import {Calendar, momentLocalizer} from "react-big-calendar";
import "./style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {trackPromise} from "react-promise-tracker";
import {errorToast} from "../../DommusToast";
import tarefasService from "../../../services/TarefasService";
import ModalDommus from "../../Modal";
import NovaTarefa from "./NovaTrefa";
import {TaskInfo} from "../../../pages/AgendamentoVisita/TaskInfo";
import guService from "../../../services/GuService";

export default function Agenda({openAgenda, closeAgenda}) {
    moment.locale("pt-br");
    const [refresh, setRefresh] = useState(false);
    const [events, setEvents] = useState([]);
    const [dataTarefa, setDataTarefa] = useState({});
    const [openModalCriaTarefa, setOpenModalCriaTarefa] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
    const [currentViewMode, setCurrentViewMode] = useState("week");
    const [info, setInfo] = useState([]);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({start: null, end: null});
    const [dentroDoPrazoAntigo, setDentroDoPrazoAntigo] = useState(false)
    const [currentStep, setCurrentStep] = useState(guService.getLocalState('step_agenda') != 'null' && guService.getLocalState('step_agenda') || 15)
    const steps=[30, 15, 5]

    const handleSelectEvent = useCallback((event) => {
        buscaInfos(event);
    }, []);
    function handleCriarTarefa(event) {
        setDataTarefa(event);
        if (event) {
            setOpenModalCriaTarefa(true);
        }
    }
    function buscaInfos(item) {
        setInfo(item);
        setOpenModalInfo(true);
    }

    const messages = {
        date: "Data",
        time: "Horário",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Semana de Trabalho",
        day: "Dia",
        month: "Mês",
        previous: "<",
        next: ">",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",

        noEventsInRange: "Não há mais eventos nesse período.",

        showMore: (total) => `+${total} mais`,
    };

    const handleCalendarNavigate = useCallback((date) => {
        setSelectedDate(moment(date));
    }, []);

    useEffect(() => {
        switch (currentViewMode) {
            case "month":
                handleSetAgendaWidth("90%");
                break;
            case "week":
                handleSetAgendaWidth("90%");
                break;
            case "day":
                handleSetAgendaWidth("60%");
                break;
            default:
                handleSetAgendaWidth("50%");
        }
    }, [currentViewMode]);

    const handleSetAgendaWidth = useCallback((widthProp = "50%") => {
        const el = document.querySelector("section.equipes");
        el.style.width = widthProp;
        el.style.minWidth = widthProp;
    }, []);

    //   useEffect(() => {
    //     handleGetEvents();
    //   }, [selectedDate]);

    let eventPropGetter = (event) => {
        if (event.finalizado) {
            return {
                className: "event-finished",
            };
        }
        return {};
    };



    let formats = {
        dateFormat: "DD",
        dayFormat: "DD",
        weekdayFormat: "ddd",
        timeGutterFormat: "HH:mm",
        monthHeaderFormat: "MMMM/YYYY",
        weekHeaderFormat: "MM/YYYY",
        dayHeaderFormat: "dddd - DD/MM",
        dayRangeHeaderFormat: ({start, end}) =>
            moment(start).format("DD/MM") + " à " + moment(end).format("DD/MM"),
    };

    const handleGetEvents = useCallback(() => {
        let dia = moment(selectedDate);
        let dataInicio = selectedDateRange.start != null ? `${moment(selectedDateRange.start).format('YYYY-MM-DD')} 00:00:00` : startOfDate(dia)
        let dataFim = selectedDateRange.end != null ? `${moment(selectedDateRange.end).format('YYYY-MM-DD')} 23:59:59` : endOfDate(dia)
        if(dentroDoPrazoAntigo) {
            const eventosFiltrados = events.filter(evento => moment(evento.data_inicio).isBetween(moment(dataInicio), moment(dataFim)))
            setEvents(eventosFiltrados)
        } else {
            trackPromise(
                tarefasService.index({data_inicio: dataInicio, data_inicio_menor: dataFim}).then(
                    (response) => {
                        let arrayEventos = [];

                        response.data.map((event) => {
                            let startTime = new Date(event.data_inicio);

                            if (event.hora) {
                                startTime = new Date(
                                    moment(event.data + " " + event.hora).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )
                                );
                            }

                            arrayEventos.push({
                                id: event.id_tarefa,
                                id_ticket: event.ticket?.id_ticket,
                                ticket: event?.ticket,
                                repetir: event.repetir,
                                title: `${event.dados_processo?.unidade?.descricao != undefined 
                                          ? event.dados_processo?.unidade?.descricao 
                                          : (event.dados_processo?.id ?? event.dados_condominio?.razao_social)} 
                                        - ${(event.dados_processo?.empreendimento?.nome || event.empreendimento?.nome_empreendimento)} 
                                        - ${event.dados_processo?.proponente1?.nome ?? ''}, 
                                          ${event.titulo ?? ''}`,
                                start: startTime,
                                end: startTime,
                                className: "event-finished",
                                detalhes: event.detalhes,
                                usuarios_responsaveis: event.usuarios_responsaveis,
                                departamentos_responsaveis: event.departamentos_responsaveis,
                                tipo_tarefa: event.tipo_tarefa,
                                data_inicio: event.data_inicio,
                                data_fim: event.data_fim,
                                titulo: event.titulo,
                                dados_processo: event.dados_processo,
                                dados_condominio: event.dados_condominio,
                                realizada: event.realizada
                            });
                        });
                        setEvents(arrayEventos);
                    },
                    (error) => {
                        errorToast.fire({text: `Ocorreu um erro ao : ${error}`});
                    }
                )
            );
        }
    }, [selectedDate]);

    useEffect(() => {
        if (openAgenda) {
            handleGetEvents();
        }
    }, [openAgenda, refresh, currentViewMode, selectedDate]);

    function handleRangeChange(range) {
        let periodoAnterior = {
            inicio: moment(selectedDateRange.start),
            fim: moment(selectedDateRange.end)
        }
        if (range.start) {
            setSelectedDate(range.start)
            setSelectedDateRange(range)
            setDentroDoPrazoAntigo(false)
        } else {
            setSelectedDate(range[0])
            setSelectedDateRange({start: null, end: null})
            range.length > 1 ?
                setDentroDoPrazoAntigo(moment(range[0]).isBetween(periodoAnterior.inicio, periodoAnterior.fim) && moment(range[6]).isBetween(periodoAnterior.inicio, periodoAnterior.fim)) :
                setDentroDoPrazoAntigo(true)
        }

    }

    function startOfDate(dataInicio) {
        return `${dataInicio.startOf(currentViewMode).format('YYYY-MM-DD')} 00:00:00`;
    }

    function endOfDate(dataFim) {
        return `${dataFim.endOf(currentViewMode).format("YYYY-MM-DD")} 23:59:59`;
    }

    function handleChangeCurrentStep(step){
        trackPromise(
            tarefasService.salvarStep(step).then(
                (response) => {
                    guService.setLocalState('step_agenda', response.data.step_agenda)
                },(error) => {
                    errorToast.fire({text: `Ocorreu um erro : ${error}`});
                }
              )
            )
        }

    function intervalOptions() {
        return <>
            <div className="dommus-interval-step">
                <label>Intervalo em minutos: </label>
                <div className="dommus-interval-step-group">
                    {steps.map((step, index) => <div key={index}
                        className={'interval-step-item' + (currentStep == step ? ' active' : '')} onClick={() => {
                        setCurrentStep(step);
                        handleChangeCurrentStep(step)
                    }}>{step * 2}</div>)}
                </div>
            </div>
        </>
    }
    return (
        <>
            <div
                className={"sidenav-overlay " + (openAgenda && "open")}
                onClick={() => {
                    closeAgenda();
                }}
            >
                &nbsp;
            </div>
            <section className={"equipes " + (openAgenda && "open")}>
                <header>
                    <div>Agenda</div>
                    <div className={"close-pane"}>
                        <FontAwesomeIcon icon={faArrowRight} onClick={closeAgenda}/>
                    </div>
                </header>
                <div>
                    <Calendar
                        selectable
                        localizer={momentLocalizer(moment)}
                        events={events}
                        views={["month", "week", "day"]}
                        defaultView={"week"}
                        messages={messages}
                        startAccessor="start"
                        endAccessor="end"
                        formats={formats}
                        step={currentStep}
                        eventPropGetter={eventPropGetter}
                        onSelectEvent={(event) => {
                            handleSelectEvent(event);
                        }}
                        onSelectSlot={(event) => {
                            handleCriarTarefa(event);
                        }}
                        onNavigate={(event) => {
                            handleCalendarNavigate(event);
                        }}
                        onView={(event) => {
                            setCurrentViewMode(event);
                        }}
                        style={{height: 500}}
                        onRangeChange={handleRangeChange}
                    />
                    <div className="dommus-custom-options">

                    <div className="agenda-do-usuario" onClick={() => {
                        window.open(`/agenda-do-usuario`);
                    }}>
                    Ir para Agenda do Usuário
                    </div>
                    {intervalOptions()}
                </div>
                </div>
            </section>
            <ModalDommus
                className="modalFiltros"
                open={openModalCriaTarefa}
                close={() => {
                    setOpenModalCriaTarefa(false);
                }}
                titulo={"Criar tarefa"}
            >
                <NovaTarefa
                    setRefresh={setRefresh}
                    refresh={refresh}
                    dataTarefa={dataTarefa}
                    cor="var(--dommus-color)"
                    setEvents={setEvents}
                    close={() => {
                        setOpenModalCriaTarefa(false);
                    }}
                    disabled={true}
                    disabledChamado={false}
                    openByAgendaFlag={true}
                />
            </ModalDommus>
            <ModalDommus
                open={openModalInfo}
                close={() => {
                    setOpenModalInfo(false);
                }}
                content={<TaskInfo infos={info}/>}
                size={"lg"}
                titulo="Informações sobre a tarefa"
            />
        </>
    );
}
