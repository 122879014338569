import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../MenuTopo/index.css";
import logo from "../../assets/LOGO_CHAPADA_DOMMUS_W2.svg";
import logoMobile from "../../assets/logo-mobile.svg";
import alarm from "../../assets/alarm.svg";
import calendar from "../../assets/calendar-alt-regular.png";
import { Icon, InlineIcon } from "@iconify/react";
import calendarOutlineBadged from "@iconify-icons/clarity/calendar-outline-badged";
import DommusOnlineFlag from "../DommusOnlineFlag";
import Agenda from "./Agenda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../MenuLateral/index";
import guService from "../../services/GuService";
import Notificacoes from "../Notificacoes";
import { isDesktop, isTablet } from "react-device-detect";
import NavegadorModulos from "../NavegadorModulos";
import { ConfiguracaoContext } from "../../contexts/ConfiguracaoContext";

export default function MenuTopo({ history }) {
  const [openAgenda, setOpenAgenda] = useState(false);
  const [openNotificacoes, setOpenNotificacoes] = useState(false);
  const {setOpenMenuMobile} = useContext(ConfiguracaoContext);

  const telas = guService.getLocalState("telas");
  function logout(e) {
    e.preventDefault();
    guService.logout();
  }

  function openLateral(){
    setOpenMenuMobile((current) => !current);
  }

  const handleOpenNotificacoes = ()=>{
    setOpenNotificacoes(!openNotificacoes);
  }

  return (
    <>
      <nav className="dommus-menu">
        <div className="logo">
          <Link to="./">
            <picture>
              {isDesktop || isTablet ?
                <img src={logo} alt="CRM - Dommus Sistemas" />
                :
                <img src={logoMobile} alt="CRM - Dommus Sistemas" />
              }
            </picture>
          </Link>
          <div className="modulo-name">
              <span>|</span>     
              <NavegadorModulos/>
          </div>
        </div>
        <div className="right-nav">
          <div className="info-nav">
            <div className="online">
              {/* <h1 className="modulo-name d-none d-md-flex">
                Módulo: Dommus Atende
              </h1> */}
              <Dropdown>
                <Dropdown.Toggle className="perfil-dropdown">
                  <DommusOnlineFlag />
                  <h1 className="user-name d-none d-md-flex">
                    Olá, {guService.getLocalState("nomeUsuario")}
                  </h1>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/*<Dropdown.Item eventKey="4" onClick={() => {}}>
                                    Perfil
                                </Dropdown.Item>
                                <Dropdown.Divider/>*/}
                  <Dropdown.Item eventKey="4">
                    <Link to="/sair">Sair</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <picture
              className={"picture-topo"}
              onClick={() => {
                setOpenAgenda((statusAtual) => {
                  return !statusAtual;
                });
              }}
            >
              <Icon icon={calendarOutlineBadged} width="30" />
            </picture>            
            <Notificacoes/>
            <FontAwesomeIcon icon={faBars} className="barsMenuMobile" onClick={() => openLateral()} />
          </div>
        </div>
      </nav>
      <Agenda
        openAgenda={openAgenda}
        closeAgenda={() => {
          setOpenAgenda(false);
        }}
      />
    </>
  );
}
