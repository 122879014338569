import React, { useMemo, useState } from "react";
import './index.css'
import { Accordion, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ContextAwareToggle } from "../coluna_direita";
import MidiaSocialConecta from "../../../../../../components/MidiaSocialConecta";
import { celularMask } from "../../../../../../components/InputTextMask";
import { MdUpdate } from "react-icons/md";
import moment from "moment";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FaHeadset } from "react-icons/fa";
import formatDommus from "../../../../../../helpers/format";
import { MensagensBody } from "./MensagensBody";

export function CardAtendimento({atendimento, index}){
    const [isOpen, setIsOpen] = useState(false);
    const verificaContatoDestino = () => {
        switch (atendimento?.meio_comunicacao?.slug) {
            case "whatsapp":
            case "voip":
            case "sms":
                return celularMask(atendimento?.contato_destino)
            case ("email"):
                return (atendimento?.contato_destino)
            case ("chatbot"):
                return ("Chatbot")
            default:
                return (atendimento.contato_destino)
        }
    }

    const handleAbrirAtendimento = ()=>{

    }

    const handleCarregarConteudo = ()=>{
        setIsOpen((current)=>!current);
    }

    return(
        <Card className="card-dados-section" onClick={()=>handleCarregarConteudo()}>
            <div className="accordion_iten">
            <ContextAwareToggle as={Card.Header} eventKey={index.toString()}>
                <div className="card-dados">
                    <div className="midia-section">
                        <MidiaSocialConecta midia={atendimento?.meio_comunicacao?.slug}/>
                    </div>
                    <div className="informacoes-section">
                        <Container>
                            <Row className="mb-1">
                                <Col md={6}>
                                    {verificaContatoDestino()}
                                </Col>
                                <Col md={6} className="right-side">
                                    <div>
                                        <MdUpdate className="icon-processo-info" />
                                        <span>{atendimento?.ultima_movimentacao && moment(atendimento?.ultima_movimentacao).format('DD/MM/YYYY HH:mm')}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={6} xs={6} className="left-side">
                                    <OverlayTrigger
                                        key="nomeAtendente"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-nome-atendente`}>
                                            Originado por
                                            </Tooltip>
                                        }
                                        >
                                    <div className="contato-origem">
                                        <BsFillTelephoneOutboundFill className="icon-registro-contato"/>
                                        <span>{atendimento.origem?.descricao || "---"}</span>
                                    </div>
                                    </OverlayTrigger>
                                </Col>
                                <Col md={6} xs={6} className="right-side">
                                    <div className="data data-inicio">
                                        {atendimento?.criado_em ? <OverlayTrigger
                                        key="dataCriacao"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-data-criacao`}>
                                            Data da criação do atendimento em: {moment(atendimento.criado_em).format('DD/MM/YYYY HH:mm')}
                                            </Tooltip>
                                        }
                                        >
                                        <BiEditAlt className="icon-processo-info" />
                                        </OverlayTrigger> : <></>}
                                        Iniciado {atendimento?.criado_em && formatDommus.formatDate(atendimento?.criado_em)}
                                    </div>
                                </Col>                               
                            </Row>
                            <Row className="mb-1">
                                <Col md={6} xs={6} className="left-side">
                                    <OverlayTrigger
                                        key="status atendimento"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-editar`}>
                                                Status do Atendimento
                                            </Tooltip>
                                        }
                                        >
                                        <div className="statusAtendimento">
                                        <span className="situacao-badge" style={atendimento?.situacao_atendimento?.cor ? { backgroundColor: `${atendimento?.situacao_atendimento?.cor}` } : { backgroundColor: `#CA965C` }}>{atendimento?.situacao_atendimento?.nome}</span>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                                <Col md={6} xs={6} className="right-side">
                                    <div className="atendenteNome" title={atendimento?.nome_atendente ? atendimento?.nome_atendente.length > 20 ? atendimento?.nome_atendente : '' : ''}>
                                        <OverlayTrigger
                                        key="nomeAtendente"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-nome-atendente`}>
                                            Nome do atendente
                                            </Tooltip>
                                        }
                                        >
                                        <FaHeadset className="icon-processo-info" />
                                        </OverlayTrigger>
                                        {atendimento?.atendente_nome ?? "---"}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={7} xs={7}>
                                    {/* <button className="btn-abrir-atendimento" 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleAbrirAtendimento();
                                        }}
                                    >
                                        Abrir atendimento Conecta
                                    </button> */}
                                </Col>
                                <Col md={5} xs={5} className="right-side">
                                    <OverlayTrigger
                                        key="IconTipoAtendimento"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-editar`}>
                                                Tipo do Atendimento
                                            </Tooltip>
                                        }
                                        >
                                        <div className="tipo-atendimento">
                                        {atendimento?.tipo_atendimento?.descricao}
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </ContextAwareToggle>
            </div>
            <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body className={` ${ atendimento?.meio_comunicacao?.slug === 'whatsapp' ? "whatsapp-item" : "normal-item"} text-center`}>
                    <MensagensBody atendimento={atendimento} isOpen={isOpen}/>           
                    {/* <button className="btn-abrir-atendimento" 
                        onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            handleAbrirAtendimento();
                        }}
                    >
                        Abrir atendimento Conecta
                    </button>                              */}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}