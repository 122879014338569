import crm from './crm';
import { trackPromise } from 'react-promise-tracker';

const configuracaoService = {
	buscarConfiguracoesVisuais:()=>{
		return crm.get('/configuracao/visuais');
	},
	buscarConfiguracoesAlterarStatusTicket:() => {
		return trackPromise(crm.get('/configuracao/alterarStatusTicket'));
	},
	buscarPorChave:(chave:any) => {
		return trackPromise(crm.get('/configuracao/' + chave));
	},
	index: (filtros=null) => {
		return crm.get('/configuracao', { params: filtros })
	}
};

export default configuracaoService;
