import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./historico.css";
import moment from "moment";
import format from "../../../../../helpers/format";
import Alert from "react-bootstrap/Alert";

import ticketService from "../../../../../services/TicketService";
import Swal from "sweetalert2";

export default function Historico({ idTicket, recarregarDadosChamado, setRecarregarDadosChamado }) {
  const [historico, setHistorico] = React.useState([]);

  React.useEffect(() => {
    ticketService.historico(idTicket).then((res) => {
      setHistorico(res.data);
    }).catch(() => {
      Swal.fire({
        title: 'Ooops...', 
        text: 'Ocorreu um erro ao carregar os dados do chamado.', 
        icon: 'error',
        confirmButtonText: `Recarregar`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if(result.isConfirmed) {
          setRecarregarDadosChamado(!recarregarDadosChamado)
        }
      })
    });
  }, [recarregarDadosChamado]);

  const ItemTimeLine = ({ titulo, descricao, data, index, usuario=null }) => {
    return (
      <TimelineItem className="item">
        <TimelineSeparator>
          <TimelineDot className="dotHistorico" />
          {historico.length !== index + 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Paper className="paper">
            <h6>{titulo}</h6>
            {descricao.map((item) => {
              return item + " ";
            })}
            {usuario ?
              <span className="user-label">{usuario ?? ""}</span> : <></>
            }
            <Typography className="labelHora">
              {moment(data).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };
  return (
    <div className="historico">
      <Timeline align="right">
        {historico ? (
          historico.map((historico, index) => (
            <div key={index}>
              <ItemTimeLine
                titulo={historico.tipo.nome}
                descricao={
                  historico.detalhe_historico
                    ? format.isJson(historico.detalhe_historico)
                      ? JSON.parse(historico.detalhe_historico)
                      : [historico.detalhe_historico]
                    : []
                }
                data={historico.criado_em}
                index={index}
                usuario={historico?.usuario ?? null}
              />
            </div>
          ))
        ) : (
          <Alert variant="warning">Nenhuma historico a ser exibida.</Alert>
        )}
      </Timeline>
    </div>
  );
}
