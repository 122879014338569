import crm from "./crm";

const notificacaoService = {
  buscarNotificacoes: () => {
    return crm.get("/notificacao/ultimas");
  },
  atualizar: (id) => {
    return crm["put"].call(crm, "/notificacao/" + id);
  },
  atualizarNotificacoes: (column) => {
    return crm["put"].call(crm, "/notificacao/", { column: column });
  },
  marcarComoNaoLida: (id) => {
    return crm["put"].call(crm, "/notificacao/nao-lida/" + id);
  },
  notificado: (id) => {
    return crm["put"].call(crm, "/notificacao/notificado/" + id);
  },
  buscarNotificacoesTipos: () => {
    return crm.get('/notificacoes-tipos');
  }
};

export default notificacaoService;
