import React, { createContext, ReactElement, useState, useContext } from "react";
import { PerguntaType, TipoRespostaPerguntaType } from "./types";
import PerguntaService from "../../services/PerguntaService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { QuestionarioContext } from "../QuestionarioContext";

interface PerguntaContextProps {
    listaPerguntas: PerguntaType[];
    listaTipoRespostaPerguntas: TipoRespostaPerguntaType[];
    recarregarPerguntas: () => void;
    recarregarTipoRespostaPerguntas: () => void;
    adicionarPergunta: (data: PerguntaType) => void;
    atualizarPergunta: (data: PerguntaType) => void;
    removerPergunta: (idPergunta: number) => void;
}

export const PerguntaContext = createContext({} as PerguntaContextProps);

export function PerguntaProvider({ children }: { children: ReactElement }) {
    const [listaPerguntas, setListaPerguntas] = useState<PerguntaType[]>([]);
    const [listaTipoRespostaPerguntas, setListaTipoRespostaPerguntas] = useState<TipoRespostaPerguntaType[]>([]);
    const {recarregarQuestionarios} = useContext(QuestionarioContext)


    const recarregarPerguntas = () => {
        trackPromise(PerguntaService.buscar())
            .then((response) => {
                setListaPerguntas(response.data.perguntas);
            })
            .catch((error) => {
                Swal.fire({
                    title: "Ooops...",
                    text: `Houve um erro ao carregar as perguntas: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`,
                    icon: 'error'
                })
            });
    };

    const recarregarTipoRespostaPerguntas = () => {
        trackPromise(PerguntaService.buscarTipoResposta())
            .then((response) => {
                setListaTipoRespostaPerguntas(response.data);
            })
            .catch((error) => {
                Swal.fire({
                    title: "Ooops...",
                    text: `Houve um erro ao carregar os tipos de resposta: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`,
                    icon: 'error'
                })
            });
    };

    const adicionarPergunta = (data: PerguntaType) => {
        setListaPerguntas((current) => {
            return [data, ...current];
        });
    };

    const atualizarPergunta = (data: PerguntaType) => {
        setListaPerguntas((current) => {
            return current.map((pergunta) => {
                if (pergunta.id_pergunta === data.id_pergunta) {
                    return data;
                }
                return pergunta;
            });
        });
    };

    const removerPergunta = (idPergunta: number) => {
        setListaPerguntas((current) => {
            return current.filter(
                (pergunta) => pergunta.id_pergunta !== idPergunta
            );
        });
    };

    return (
        <PerguntaContext.Provider
            value={{
                listaPerguntas,
                listaTipoRespostaPerguntas,
                recarregarPerguntas,
                recarregarTipoRespostaPerguntas,
                adicionarPergunta,
                atualizarPergunta,
                removerPergunta,
            }}
        >
            {children}
        </PerguntaContext.Provider>
    );
}
