import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import InternalNoteTicketService from "../../../../../services/InternalNoteTicketService";
import "../index.css";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface InternalNoteProps {
    id_ticket: number,
    closeModal: () => void,
    atualizaChamado: () => void,
    statusMudou?: () => void
}

export function InternalNoteStatus (
    {
        id_ticket,
        closeModal,
        atualizaChamado,
        statusMudou
    } : InternalNoteProps )
{

    const [notaInterna, setNotaInterna] = useState("");

    function salvarNotaInterna() {
        let dados = {
            mensagem: notaInterna,
        }

        if ( notaInterna === "" )
        {
            Swal.fire({
                titleText: "Erro!",
                text: "Preencha todos os campos obrigatórios",
                icon: "error"
            })
            return;
        } else {
            InternalNoteTicketService.store(id_ticket,dados).then(
                (res) => {
                    Swal.fire({
                        titleText: "Sucesso!",
                        text: "Nota Interna registrada com sucesso.",
                        icon: "success"
                    }).then((result) => {
                        if ( result.isConfirmed )
                        {
                                closeModal()
                                atualizaChamado()

                                if ( statusMudou ) {
                                    statusMudou()
                                }
                        }
                    })
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar a nota interna : " + error,
                        icon: "error",
                    })
                }
            )
        }
    }

    return (
        <>
            <Container fluid>
                <Form className="formNotaInterna">
                    <Row>
                        <Form.Group as={Col} controlID="formNotaInterna">
                            <Form.Label>
                                Nota Interna: <DommusRequiredSpan />
                            </Form.Label>
                            <Form.Control
                                value={notaInterna}
                                type="textarea"
                                as="textarea"
                                rows={3}
                                onChange={(event) => setNotaInterna(event.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="formFooter">
                        <Col>
                            <button
                                type="button"
                                onClick={() => salvarNotaInterna()}
                                className="btn btn-success"
                            >
                                <FontAwesomeIcon
                                    icon={faSave as IconProp}
                                    style={{ marginRight: "15px" }}
                                />
                                Salvar
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}
