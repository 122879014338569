import React, { Dispatch, TextareaHTMLAttributes, useRef } from "react";
import { FormControl } from "react-bootstrap";
import "./index.css"
import DommusRequiredSpan from "../DommusRequiredSpan";

interface DommusTextAreaResizeProps {
    text: string;
    setText: Dispatch<React.SetStateAction<string>>
    label: string;
    placeholder?: string | null;
    required?: boolean;
}

export function DommusTextAreaResize({text, setText, label, placeholder, required=false}:DommusTextAreaResizeProps) {
    const refInputMenssage = useRef<HTMLTextAreaElement>(null);

    const atualizarQuantidadeLinhasTextarea = () => {
        const textarea = refInputMenssage?.current;
        if(textarea){
            textarea.rows = 1;
            const quantidadeLinhasTextearea = Math.ceil(
                textarea.scrollHeight /
                    parseFloat(getComputedStyle(textarea).lineHeight)
            );
            textarea.rows =
                quantidadeLinhasTextearea >= 6 ? 6 : quantidadeLinhasTextearea - 1;
            if (textarea.rows >= 2) {
                textarea.style.borderTopLeftRadius = "10px";
                textarea.style.borderTopRightRadius = "10px";
            } else {
                textarea.style.borderTopLeftRadius = "0";
                textarea.style.borderTopRightRadius = "0";
            }
        }
    };

    return (
        <div className="mensagem-section">
            <label>Mensagem: {required && <DommusRequiredSpan/>}</label>
            <FormControl
                className="textarea-galeria"
                aria-label="Mensagens"
                ref={refInputMenssage}
                value={text}
                placeholder={placeholder ?? ""}
                as="textarea"
                autoFocus={true}
                rows={1}
                onChange={(e) => {
                    if (e.target.value.length <= 1600) {
                        atualizarQuantidadeLinhasTextarea();
                        setText(e.target.value);
                    }
                }}
                required={required}
            />
        </div>
    )
}