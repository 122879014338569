import React, { useMemo } from "react";
import { Card, Alert, Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import "./financeiro.css";
import moment from "moment";
import Swal from "sweetalert2";
import { successToast } from "../../../../../components/DommusToast";
import guService from "../../../../../services/GuService";
import ProcessoService from "../../../../../services/ProcessoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default function Financeiro({ financeiro, erro }) {
  const [segundaVia, setSegundaVia] = React.useState(false);
  const [loadingBoleto, setLoadingBoleto] = React.useState(false);

  function handleBuscaBoleto(chaves) {
    if (chaves == null) {
      Swal.fire({
        titleText: "Boleto não disponível, entre em contato conosco.",
        text: "",
        icon: "warning",
      });
    } else {
      buscaBoleto(
        guService.getLocalState("Processo"),
        chaves.id_externo_parcela,
        chaves.id_externo_venda
      );
    }
  }

  function buscaBoleto(processo, parcela, venda) {
    setLoadingBoleto(true);
    ProcessoService.buscarBoleto(processo, parcela, venda).then((res) => {
      const retorno = res.data;
      setLoadingBoleto(false);
      if (retorno.length > 0) {
        Swal.fire({
          titleText: `2ª via de boleto`,
          text: "Seu boleto está disponível para download",
          icon: "info",
          showCloseButton: false,
          showCancelButton: true,
          showDenyButton: true,
          focusConfirm: false,
          confirmButtonText: "Baixar boleto",
          denyButtonText: "Copiar código",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success mr-3",
            cancelButton: "btn btn-secondary mr-3",
            denyButton: "btn btn-primary mr-3",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(retorno[0].urlReport, "_blank");
          } else if (result.isDenied) {
            navigator.clipboard.writeText(retorno[0].digitableNumber);
            successToast.fire({
              text: "Link copiado para a área de transferência.",
            });
          }
        });
      } else {
        Swal.fire({
          titleText: "Boleto não disponível, entre em contato conosco.",
          text: "",
          icon: "warning",
        });
        setLoadingBoleto(false);
      }
    });
  }

  const dadosFinanceiros = useMemo(() => {
    if(financeiro){
      return (
        <>
          <Card.Body className="cardTabela">
            <div className="conteudoFianceiro">
              <Table className="tebelaFinanceiro" striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tipo Parcela</th>
                    <th>Vencimento</th>
                    <th>Valor(R$)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {financeiro ? (
                    financeiro.map((financeiro, index) => {
                      let chaves = JSON.parse(financeiro.chaves_externas);
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{financeiro.tipo}</td>
                          <td>
                            {moment(financeiro.vencimento).format("DD/MM/YYYY")}
                          </td>
                          <td>{financeiro.valor}</td>
                          <td
                            onMouseEnter={() => {
                              setSegundaVia(true);
                            }}
                            onMouseLeave={() => {
                              setSegundaVia(false);
                            }}
                          >
                            {financeiro.statusParcela === "quitada" ? (
                              <FontAwesomeIcon
                                title={financeiro.statusParcela}
                                icon={faCheckCircle}
                                color="green"
                              />
                            ) : financeiro.statusParcela === "avencer" ? (
                              <FontAwesomeIcon
                                title={financeiro.statusParcela}
                                icon={faExclamationCircle}
                                color="orange"
                              />
                            ) : financeiro.statusParcela === "atrasada" ? (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                color="red"
                                title={financeiro.statusParcela}
                              />
                            ) : (
                              ""
                            )}
                            {segundaVia ? (
                              loadingBoleto ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  size="sm"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  title={"2°via"}
                                  icon={faBarcode}
                                  color="#444"
                                  style={{ marginLeft: 5, cursor: "pointer" }}
                                  onClick={() => {
                                    handleBuscaBoleto(chaves);
                                  }}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </>
      )
    }else{
      return(
        <Alert variant="warning">
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
          />
          <span style={{paddingRight: "10px"}}>Carregando Dados</span>
        </Alert>
      )
    }
  },[financeiro]);

  return erro && <Alert>{erro}</Alert> || dadosFinanceiros    
}
