import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDommus from '../../helpers/format';

export default function MeioComunicacaoIcone({ midia, className = null, tooltipPrefix="" }) {
    let midiaSelecionada = formatDommus.iconesMeiosComunicacao[midia];
    if (!midiaSelecionada) {
        midiaSelecionada = 'cadastro-manual';
    }
    const icon = midiaSelecionada.icone;
    const color = midiaSelecionada.cor;
    const description = tooltipPrefix + midiaSelecionada.descricao;
    const background = midiaSelecionada.background;

    return (
        <>
            <div className={className} style={{ backgroundColor: background}} data-tip={description}>
                <FontAwesomeIcon
                    style={{aspectRatio: '1/1'}}
                    icon={icon}
                    color={color}
                />
            </div>
        </>
    )
}
