import React, {useState, useEffect} from "react";
import {Nav, Tab, Col} from "react-bootstrap";
import "./index.css";
import Mensagens from "./Chat/mensagem";
import Input from "./Chat/input";
import Coluna_direita from "./ColunaDireita/coluna_direita";
import Coluna_esquerda from "./ColunaEsquerda/coluna_esquerda";
import ticketService from "../../../../services/TicketService";
import {trackPromise} from "react-promise-tracker";
import Swal from "sweetalert2";
import NotasInternas from "./Chat/notasInternas";
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faCommentAlt, faIdCard } from "@fortawesome/free-solid-svg-icons";

export default function Chamado({chamado, close, setRefresh, refresh}) {
    const [leftOpen, setLeftOpen] = useState(true);
    const [rightOpen, setRightOpen] = useState(true);
    const [orientacaoTela, setOrientacaoTela] = useState("portrait");
    const [mensagens, setMensagens] = useState([]);
    const [notasInternas, setNotasInternas] = useState([]);
    const [recarregarDadosChamado, setRecarregarDadosChamado] = useState(false)
    
    let leftOpenClass = leftOpen ? "open" : "closed";
    let rightOpenClass = rightOpen ? "open" : "closed";
    
    useEffect(() => {
        const mediaQueryLandscape = window.matchMedia('(orientation: landscape)');
        const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');

        if (mediaQueryPortrait.matches) {
            setOrientacaoTela('portrait');
        } else if (mediaQueryLandscape.matches) {
            setOrientacaoTela('landscape');
        }

    }, []);


    function onSendMessage(message, arquivos=null) {
        let dados = [];
        dados = [{
                mensagem: message,
                tipo: "A",
                dados_arquivo: arquivos ?? []
            }]

        Swal.fire({
            titleText: "Envio ao cliente",
            html: "Você tem certeza que deseja enviar uma mensagem para o cliente? Esta ação não poderá ser revertida.",
            icon: "warning",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, enviar",
            cancelButtonText: "Cancelar",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                trackPromise(
                    ticketService.storeMensagem(chamado.id_ticket, dados).then(
                        (response) => {
                            carregaMensagens();
                        },
                        (error) => {
                            Swal.fire({
                                titleText: "Ooops...",
                                text: "Ocorreu um erro ao enviar a mensagem: " + error,
                                icon: "error",
                            });
                        }
                    )
                );
            }
        });
    }

    function onSendNote(message, arquivos=null) {
        let dados = [];
        dados =  [{
            mensagem: message,
            dados_arquivo: arquivos ?? []
        }];        
        trackPromise(
            ticketService.storeNotaInterna(chamado.id_ticket, dados).then(
                (response) => {
                    carregaNotas();
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar nota interna: " + error,
                        icon: "error",
                    });
                }
            )
        );
    }

    useEffect(() => {
        carregaMensagens();
        carregaNotas();
        var myinterval = setInterval(function () {
            carregaMensagens();
            carregaNotas();
        }, 10000);
        return function cleanup() {
            clearInterval(myinterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function carregaMensagens() {
        ticketService.getMensagemByTicket(chamado.id_ticket).then(
            (response) => {
                let itensListaMensagens = [];
                Object.values(response.data).forEach((item) => {
                    itensListaMensagens.push({
                        text: item.mensagem,
                        url: item.url,
                        tipo_upload: item.tipo_upload,
                        tipo: item.tipo,
                        id_usuario: item.usuario?.id_usuario || "",
                        nome: item.usuario?.nome || "",
                        criado_em: item.criado_em,
                        arquivos: item?.arquivos ?? [],
                    });
                });
                setMensagens(itensListaMensagens);
            },
            (error) => console.log(`Erro ao buscar as mensagens: ${error}`)
        );
    }

    function carregaNotas() {
        ticketService.getNotaInternaByTicket(chamado.id_ticket).then(
            (response) => {
                let itensListaNotas = [];
                Object.values(response.data).forEach((item) => {
                    itensListaNotas.push({
                        id_ticket_nota_interna: item?.id_ticket_nota_interna,
                        text: item.mensagem,
                        tipo: item.tipo,
                        id_usuario: item.usuario?.id_usuario,
                        nome: item.usuario?.nome,
                        url: item.url,
                        criado_em: item.criado_em,
                        id_usuario_exclusao: item?.usuario_exclusao?.id_usuario_exclusao,
                        nome_usuario_exclusao: item?.usuario_exclusao?.nome,
                        data_exclusao: item?.data_exclusao,
                        ativo: item?.ativo,
                        arquivos: item?.arquivos ?? [],
                    });
                });

                setNotasInternas(itensListaNotas);
            },
            (error) => console.log(`Erro ao buscar os tickets: ${error}`)
        );
    }

    function atualizarNota(nota){
        setNotasInternas((current)=>{
            return current.map((item)=>{
                let obj = item;
                if(item.id_ticket_nota_interna === nota.id_ticket_nota_interna){
                    obj = nota;
                }
                return {
                    id_ticket_nota_interna: obj?.id_ticket_nota_interna,
                    text: obj.mensagem ?? obj.text,
                    tipo: obj.tipo,
                    id_usuario: obj.usuario?.id_usuario ?? obj?.id_usuario,
                    nome: obj.usuario?.nome ?? obj.nome,
                    url: obj.url,
                    criado_em: obj.criado_em,
                    id_usuario_exclusao: obj?.usuario_exclusao?.id_usuario_exclusao ?? obj?.id_usuario_exclusao,
                    nome_usuario_exclusao: obj?.usuario_exclusao?.nome ?? obj.nome_usuario_exclusao,
                    data_exclusao: obj?.data_exclusao,
                    ativo: obj?.ativo
                };                
            })
        })
    }

    function leftSide(){
        return (
            <div id="left" className={leftOpenClass}>
                <div className={`sidebar ${leftOpenClass}`}>
                    <div className="content">
                        <Coluna_esquerda
                            ticket={chamado}
                            close={close}
                            setRefresh={setRefresh.bind(this)}
                            refresh={refresh}
                            setRecarregarDadosChamado={setRecarregarDadosChamado}
                            recarregarDadosChamado={recarregarDadosChamado}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function centralSide(){
        return (
            <div id="main" className="msg">
                <Tab.Container id="left-tabs" defaultActiveKey="mensagens">
                    <div className="content">
                        <div className="header">
                            <Nav variant="pills" className="chat">
                                <Col>
                                    <Nav.Item>
                                        <Nav.Link eventKey="mensagens">Mensagens</Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col>
                                    <Nav.Item>
                                        <Nav.Link eventKey="notas_internas">
                                            Notas Internas
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="mensagens">
                                <div className="listaMensagens">
                                    <Mensagens
                                        mensagens={mensagens}
                                        usuario={chamado.id_usuario_solicitante}
                                    />
                                    <div className="input-section">
                                        <Input onSendMessage={onSendMessage}/>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="notas_internas">
                                <div className="listaMensagens">
                                    <NotasInternas
                                        mensagens={notasInternas}
                                        atualizarNota={atualizarNota}
                                        usuario={chamado.id_usuario_solicitante}
                                    />
                                    <div className="input-section">
                                        <Input onSendMessage={onSendNote}/>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        )
    }

    function rightSide(){
        return (
            <div id="right" className={rightOpenClass}>
                <div className={`sidebar ${rightOpenClass}`}>
                    <Coluna_direita ticket={chamado} setRecarregarDadosChamado={setRecarregarDadosChamado} recarregarDadosChamado={recarregarDadosChamado}/>
                </div>
            </div>
        )
    }

    function mobileViewComponent(){
        return(
            <div className="mobile-view">
                <Tab.Container defaultActiveKey="left-side">
                        <div className="content-mobile">
                            <Tab.Content>
                                <Tab.Pane eventKey="left-side">
                                    {leftSide()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="central-side">
                                    {centralSide()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="right-side">
                                    {rightSide()}
                                </Tab.Pane>
                            </Tab.Content>
                            <div className="header-selection-mobile">
                                <Nav variant="pills" className="chat">
                                    <Col xs={4} sm={4} lg={4}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="left-side">
                                                <FontAwesomeIcon icon={faClipboardList}/>
                                                <span>Chamado</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="central-side">
                                                <FontAwesomeIcon icon={faCommentAlt}/>
                                                <span>Mensagens</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col xs={4} sm={4} lg={4}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="right-side">
                                                <FontAwesomeIcon icon={faIdCard} />
                                                <span>Cliente</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                </Nav>
                            </div>
                        </div>
                </Tab.Container>
            </div>
        )
    }

    return (
        <>
            {(isDesktop || isTablet && orientacaoTela === "landscape") &&
                <div id="chamado" style={{fontSize: 12}}>
                    {leftSide()}
                    {centralSide()}
                    {rightSide()}                
                </div>
            }
            {(isMobile && !isTablet) || (isTablet && orientacaoTela === "portrait") ?
                <div id="chamado-mobile">
                    {mobileViewComponent()}
                </div>            
                :
                <></>
            }
        </>
    );
}
