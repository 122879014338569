import React, { useEffect } from "react";
import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faCopy, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import {
  celularMask,
  cepMask,
  cpfMask,
  identidadeMask,
} from "../../../../../components/InputTextMask";
import Mapa from "./maps";
// import "./informacoesCadastrais.scss";
import moment from "moment";
import helper from "../../../../../helpers/format";
import stringHelper from "../../../../../helpers/StringHelper";

const location = {
  address: "Belo Horizonte, MG",
  lat: -19.897496328764273,
  lng: -43.94277516976184,
};

export default function InformacoesCadastrais({ cliente }) {
  return (
    <>
      <Card.Body>
        <div className="dadosPessoaisCliente">
          <Row
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <Col xs={3} sm={3} lg={3}>
              <div className="foto">
                <div className="placeholder">
                  <div className=" fotoEnvolvido">
                    <FontAwesomeIcon icon={faUserCircle} color="#444444" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={9} sm={9} lg={9}>
              <div className="nomeCliente">
                <Card.Text>
                  <b>{cliente?.nome ?? cliente.nome_proponente}</b>
                  <span data-tip="Copiar">
                      <FontAwesomeIcon 
                        className="copy-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          if(cliente?.nome) {
                            stringHelper.copyBoard(cliente?.nome ?? cliente?.nome_proponente)
                          }
                        }} icon={faClone} />
                    </span>
                </Card.Text>
              </div>
              <div className="telefoneCliente">
                <Card.Text>
                    <span>{cliente?.telefone1 ?? celularMask(cliente?.telefone_proponente ?? "")}</span>
                    {cliente.telefone1 && 
                      <span data-tip="Copiar">
                        <FontAwesomeIcon 
                          className="copy-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if(cliente?.telefone1) {
                              stringHelper.copyBoard(cliente?.telefone1 ?? cliente?.telefone_proponente)
                            }
                          }} icon={faClone} />
                      </span>
                    }
                </Card.Text>
              </div>
              <div className="telefoneCliente">
                <Card.Text>
                  <span>{cliente.telefone2}</span>
                  {cliente.telefone2 && 
                    <span data-tip="Copiar">
                      <FontAwesomeIcon 
                        className="copy-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          if(cliente?.telefone2) {
                            stringHelper.copyBoard(cliente?.telefone2)
                          }
                        }} icon={faClone} />
                    </span>
                  }
                </Card.Text>
              </div>
              <div className="emailCliente">
                <Card.Text>
                  <span>{cliente?.email ?? cliente?.email_proponente}</span>
                  {cliente?.email && 
                    <span data-tip="Copiar">
                      <FontAwesomeIcon 
                        className="copy-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          if(cliente?.email) {
                            stringHelper.copyBoard(cliente?.email ?? cliente?.email_proponente)
                          }
                        }} icon={faClone} />
                    </span>
                  }
                </Card.Text>
              </div>
            </Col>
          </Row>
          <hr />
          <div className="dados-proponente">
            <Row className="row-gap">            
              <Col xs={12} sm={12} lg={12} xl={6}>
                <div className="item-info">
                  <label>CPF:</label>
                  <div className="item-copy">
                    <span>{cliente.cpf ?? cpfMask(cliente.cpf_proponente) ?? ""}</span>      
                    {cliente?.cpf && 
                      <div data-tip="Copiar">
                        <FontAwesomeIcon 
                          className="copy-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if(cliente?.cpf) {
                              stringHelper.copyBoard(cliente?.cpf ?? cliente.cpf_proponente)
                            }
                          }} icon={faClone} />
                      </div>
                    }  
                    </div>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12} xl={6}>
                <div className="item-info">
                  <label>Documento:</label>
                  <span>{cliente.tipoDeDocumento} -{" "} {cliente.numeroDoDocumento}</span>        
                </div>
              </Col>
            </Row>
            <Row className="row-gap">            
              <Col xs={6} sm={6} lg={6} xl={3}>
                <div className="item-info">
                  <label>Nascimento:</label>
                  <span>  
                  {cliente.nascimento === null ||
                    cliente.nascimento === undefined ||
                    cliente.nascimento === ""
                      ? "---"
                      : moment(cliente.nascimento).format("DD/MM/YYYY")}
                  </span>        
                </div>
              </Col>
              <Col xs={6} sm={6} lg={6} xl={3}>
                <div className="item-info">
                  <label>Idade:</label>
                  <span>
                  {cliente.nascimento === null ||
                    cliente.nascimento === undefined ||
                    cliente.nascimento === ""
                      ? "---"
                      : (helper.calcularIdade(
                          moment(cliente.nascimento).format("DD/MM/YYYY")
                        ) + " anos")}                    
                  </span>        
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12} xl={6}>
                <div className="item-info">
                  <label>Gênero:</label>
                  <span>{cliente.genero}</span>        
                </div>              
              </Col>
            </Row>                 
            <hr />
            <Row className="row-gap">
              <Col lg={cliente.endereco != undefined && cliente.endereco.complemento ? 8 : 12}>
                <div className="item-info">
                  <label>Logradouro:</label>
                  <span>
                    {cliente.endereco != undefined ? cliente.endereco.logradouro : ""}
                    {cliente.endereco != undefined
                      ? " - " + cliente.endereco.numero == null
                        ? ""
                        : "--" + cliente.endereco.numero ?? "-"
                      : "-"}
                  </span>        
                </div>
              </Col>
              {cliente.endereco != undefined && cliente.endereco.complemento ? (
                <Col lg={4}>
                  <div className="item-info">
                    <label>Complemento:</label>
                    <span>
                      {cliente?.endereco?.complemento ?? ""}
                    </span>        
                  </div>              
                </Col>
              ) : <></>}
            </Row>                           
            <Row className="row-gap">
              <Col xs={12} sm={12} lg={12} xl={6}>
                <div className="item-info">
                  <label>Bairro:</label>
                  <span>{cliente?.endereco?.bairro ?? "---"}</span>        
                </div>              
              </Col>
              <Col xs={12} sm={12} lg={12} xl={6}>
                <div className="item-info">
                  <label>CEP:</label>
                  <div className="item-copy">
                    <span>
                      {stringHelper.cepMask(cliente?.endereco?.cep) ?? "--"}
                    </span>        
                    {cliente?.endereco?.cep && 
                        <div data-tip="Copiar">
                          <FontAwesomeIcon 
                            className="copy-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if(cliente?.endereco?.cep) {
                                stringHelper.copyBoard(stringHelper.cepMask(cliente?.endereco?.cep))
                              }
                            }} icon={faClone} />
                        </div>
                      }  
                  </div>
                </div>              
              </Col>
            </Row>                      
            <Row>
              <Col>
                <div className="item-info">
                  <label>Cidade/UF:</label>
                  <span>
                    {cliente?.endereco?.cidade ?? "--"}/
                    {cliente?.endereco?.uf ?? "---"}
                  </span>        
                </div>              
              </Col>              
            </Row>
          </div>
        </div>
        <div>{/* <Mapa location={location} zoomLevel={13} />  */}</div>
      </Card.Body>
    </>
  );
}
