import crm from "./crm";
import qs from "qs";

const QuestionarioService = {
    buscar: (idsQuestionarios) => {
        return crm.get("/questionario", {
            params: {idsQuestionarios},
            paramsSerializer: params => qs.stringify(params, {arrayformat: 'repeat'})
        });
    },
    buscarRespostas: (idTicket, idsQuestionarios) => {
        return crm.get(`/questionario/ticket/${idTicket}/respostas`, {
            params: {idsQuestionarios},
            paramsSerializer: params => qs.stringify(params, {arrayformat: 'repeat'})
        });
    },
    store: (dados) => {
        let uri = `/questionario`;
        return crm["post"].call(crm, uri, dados);
    },
    update: (id, dados) => {
        let uri = "/questionario/" + id;
        return crm["put"].call(crm, uri, dados);
    },
    delete: (id) => {
        let uri = "/questionario/" + id;
        return crm["delete"].call(crm, uri);
    },
    alterarStatusPergunta: (idQuestionario, idPergunta, dados) => {
        let uri = `/questionario/${idQuestionario}/pergunta/${idPergunta}`;
        return crm["put"].call(crm, uri, dados);
    },
    deletePergunta: (idQuestionario, idPergunta) => {
        let uri = `/questionario/${idQuestionario}/pergunta/${idPergunta}`;
        return crm["delete"].call(crm, uri);
    },
    addPerguntasQuestionario: (idQuestionario, dados) => {
        let uri = `/questionario/${idQuestionario}/perguntas`;
        return crm["post"].call(crm, uri, dados);
    },
    reordenarPerguntasQuestionario: (idQuestionario, ordem) => {
        let uri = `/questionario/${idQuestionario}/perguntas`;
        return crm["put"].call(crm, uri, { ordem });
    },    
    buscarQuestionariosPorTicket: (idTicket, idEmpreendimento) => {
        let uri = `questionario/ticket/${idTicket}${idEmpreendimento ? `/empreendimento/${idEmpreendimento}` : ""}`;
        return crm["get"].call(crm, uri);
    },
    buscarPermissoes: ()=>{
        let uri = `questionario/ticket/permissoes`;
        return crm['get'].call(crm, uri);
    },
    storeRespostasQuestionario: (idOportunidade, idQuestionario, respostas) => {
        let uri = `resposta/oportunidade/${idOportunidade}/questionario/${idQuestionario}`;
        return crm["post"].call(crm, uri, { respostas });
    },
    buscarRespostasPorOportunidade: (idOportunidade) => {
        let uri = `resposta/oportunidade/${idOportunidade}`;
        return crm["get"].call(crm, uri);
    },
    gerarLinkPublico: (dados)=>{
        let uri = `questionario-publico`;
        return crm["post"].call(crm, uri, dados);
    },
    buscarQuestionariosPublicosTicket: (idTicket, idQuestionario)=>{
        let uri = `questionario-publico/ticket/${idTicket}/questionario/${idQuestionario}`;
        return crm["get"].call(crm, uri);
    },
    removerQuestionarioPublico: (idQuestionarioPublico)=>{
        let uri = `questionario-publico/${idQuestionarioPublico}`;
        return crm["delete"].call(crm, uri);
    },
    buscarTemplatesPorMeioComunicacao: (meioComunicacao)=>{
        let uri = `questionario-publico/template/meio-comunicacao/${meioComunicacao}`;
        return crm['get'].call(crm, uri);        
    },
    dispararLinkproponentes: (dados)=>{
        let uri = `questionario-publico/disparo-proponentes`;
        return crm['post'].call(crm, uri, dados);
    },
};

export default QuestionarioService;
