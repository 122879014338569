import React from "react";
import "./index.css";
import moment from "moment";

export function MensagemTexto({ atendimento, conteudo }) {
    return (
        <div
            className={`mensagem-texto ${
                conteudo?.direcao === "recebido"
                    ? "mensagem-recebida-texto"
                    : "mensagem-enviada-texto"
            }`}
        >
            <span className="mensagem-conteudo">{conteudo?.conteudo}</span>
            <div className="dados-mensagem">
                {conteudo?.direcao === "recebido" ? (
                    <span>{`${atendimento?.nome ?? "Não atribuido"} - ${moment(
                        conteudo?.criado_em
                    ).format("HH:mm")}`}</span>
                ) : (
                    <span>{`${atendimento?.atendente_nome} - ${moment(
                        conteudo?.criado_em
                    ).format("HH:mm")}`}</span>
                )}
            </div>
        </div>
    );
}
