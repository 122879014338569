import './index.css';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faTicketAlt} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from '../Modal';

function DommusToggle(props) {
	const [toggle, setToggle] = useState(props.toggle || false);
	const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setToggle(props.toggle);
    }, [props.toggle])

	const headerClass = toggle ? 'dommus-toggle' : 'dommus-toggle arrow';
	return (
		<>
			<header className={`${headerClass} ${props.classeTitulo}`}>{props.title}
			{props.icones ? (
					<div className='dommus-toggle-icones'>
						{props.icones}
						<FontAwesomeIcon icon={faAngleDown} 
						onClick={() => {
							setToggle(!toggle);
							(props.callback && props.callback(toggle));
						}}/>
					</div>
				) : <FontAwesomeIcon icon={faAngleDown} 
						onClick={() => {
							setToggle(!toggle);
							(props.callback && props.callback(toggle));
						}}
					/>
			}</header>
			<div style={{'display': (toggle) ? 'block' : 'none'}} className={props.classNames}>
				{toggle}
				{props.children}
			</div>

		</>
	)
}

export default DommusToggle;
