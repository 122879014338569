import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import * as htmlToImage from "html-to-image";
import { QuestionarioPublicoType } from "../../contexts/QuestionarioContext/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import stringHelper from "../../helpers/StringHelper";
import DommusRequiredSpan from "../DommusRequiredSpan";
import "./index.css";

interface QrCodeLinkQuestionarioPublicoProps {
    questionarioPublico: QuestionarioPublicoType;
    handleGerarLinkQuestionarioPublico: (dados:any) => void;
}

export function QrCodeLinkQuestionarioPublico({ questionarioPublico, handleGerarLinkQuestionarioPublico }: QrCodeLinkQuestionarioPublicoProps) {
    const qrCodeRef = useRef(null);
    const [slaExpira, setSlaExpira] = useState(questionarioPublico.sla_expira ?? 120);

    const exportarQrCode = () => {
        if (qrCodeRef?.current) {
            htmlToImage
                .toPng(qrCodeRef?.current, { width: 800, height: 800 })
                .then(function (dataUrl) {
                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = "qr-code.png";
                    link.click();
                })
                .catch(function (error) {
                    console.error("Error generating QR code:", error);
                });
        }
    }

    return (
        questionarioPublico?.link_publico ?
            <div className="link-avulso-gerado">
                <div className="qr-code-section">
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={questionarioPublico.link_publico}
                        viewBox={`0 0 256 256`}
                        ref={qrCodeRef}
                    />
                    <OverlayTrigger
                        key="iconCopy"
                        placement="top"
                        overlay={
                            <Tooltip
                                id={"qr-code-publico"}
                            >
                                Clique para baixar
                            </Tooltip>
                        }
                    >
                        <div className="exportar-overflow" onClick={() => { exportarQrCode() }}>
                            <FontAwesomeIcon icon={faDownload as IconProp} />
                        </div>
                    </OverlayTrigger>
                </div>
                <div className="link-section">
                    <OverlayTrigger
                        key="iconCopy"
                        placement="top"
                        overlay={
                            <Tooltip
                                id={"link-publico"}
                            >
                                {questionarioPublico?.link_publico}
                            </Tooltip>
                        }
                    >
                        <span>
                            {questionarioPublico?.link_publico}
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key="iconCopy"
                        placement="top"
                        overlay={
                            <Tooltip
                                id={"link-publico-copiar"}
                            >
                                Copiar
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faCopy as IconProp} className="icon-copy" onClick={() => {
                            stringHelper.copyBoard(questionarioPublico?.link_publico)
                        }} />
                    </OverlayTrigger>
                </div>
            </div>
            : <Row className="align-items-center">
                <Form.Group as={Col} md="6" sm="12">
                    <Form.Label style={{ fontSize: "0.8rem", color: "grey" }}>SLA de Expiração: <DommusRequiredSpan /></Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            min={1}
                            value={slaExpira}
                            onBlur={(event: any) => {
                                let valor = Number(event?.target?.value);
                                if (valor <= 0) {
                                    valor = 120;
                                }
                                setSlaExpira(valor)
                            }}
                            onChange={(event) => {
                                let valor = Number(event?.target?.value);
                                setSlaExpira(valor)
                            }}
                        />
                        <InputGroup.Text>Horas</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Col lg={6} sm={12} className="mt-lg-3">
                    <button 
                        className="btn btn-tipo-link link-avulso" 
                        onClick={() => { 
                            const dados = {
                                sla_expira: slaExpira
                            }
                            handleGerarLinkQuestionarioPublico(dados) 
                        }}>
                        <FontAwesomeIcon icon={faLink as IconProp} /> Gerar link avulso
                    </button>
                </Col>
            </Row>
    )
}