import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import moment from "moment";
import RecebeImages from "./ReceberImages";
import RecebeVideo from "./RecebeVideo";
import RecebeAudio from "./RecebeAudio";
import FileDownload from "./FileDownload";

export function WhatsappComponent({ atendimento, conteudo }) {
    const [detalhes, setDetalhes] = useState({});

    useEffect(() => {
        if (typeof conteudo.detalhes === "string") {
            const novoDetalhes = JSON.parse(conteudo.detalhes);
            setDetalhes(novoDetalhes);
        } else {
            const novoDetalhes = conteudo.detalhes || {};
            setDetalhes(novoDetalhes);
        }
    }, [conteudo.detalhes]);

    const resolverConteudoMensagem = useCallback(() => {
        let msgContent;
        switch (detalhes.type) {
            case "img":
                msgContent = (
                    <RecebeImages
                        conteudo={conteudo.conteudo}
                        url={detalhes.url}
                    />
                );
                break;
            case "video":
                msgContent = (
                    <RecebeVideo
                        conteudo={conteudo.conteudo}
                        url={detalhes.url}
                    />
                );
                break;
            case "audio":
                msgContent = (
                    <RecebeAudio
                        conteudo={conteudo.conteudo}
                        url={detalhes.url}
                    />
                );
                break;
            case "file":
            case "msword":
            case "pdf":
                if (detalhes.url) {
                    msgContent = (
                        <FileDownload
                            conteudo={
                                detalhes.nome_arquivo ||
                                "Anexo " +
                                    ((conteudo.direcao === "recebido" &&
                                        "recebido") ||
                                        "enviado")
                            }
                            url={detalhes.url}
                        />
                    );
                } else {
                    msgContent = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: conteudo.conteudo,
                            }}
                        ></div>
                    );
                }
                break;
            case "text":
            default:
                msgContent = (
                    <div
                        dangerouslySetInnerHTML={{ __html: conteudo.conteudo }}
                    ></div>
                );
                break;
        }

        return msgContent;
    }, [detalhes.type, conteudo.conteudo]);

    return (
        <>
            <div
                className={`mensagem ${
                    conteudo?.direcao === "recebido"
                        ? "mensagem-recebida"
                        : "mensagem-enviada"
                }`}
            >
                <span className="mensagem-conteudo">{resolverConteudoMensagem()}</span>
                <div className="dados-mensagem">
                    {conteudo?.direcao === "recebido" ? (
                        <span>{`${atendimento?.nome} - ${moment(
                            conteudo?.criado_em
                        ).format("HH:mm")}`}</span>
                    ) : (
                        <span>{`${atendimento?.atendente_nome} - ${moment(
                            conteudo?.criado_em
                        ).format("HH:mm")}`}</span>
                    )}
                </div>
            </div>
        </>
    );
}
