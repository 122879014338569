import crm from './crm';

const empreendimentoService = {
	index:(query=null)=>{
		return crm.get('/empreendimentos', { params: query });
	},
	buscarRegionais: () => crm.get('/regionais'),
	buscarDadosEmpreendimento: (idEmpreendimento, queryString=null) => crm.get(`/empreendimentos/${idEmpreendimento}`, { params: queryString }),
	buscarCondominios: () => crm.get('/empreendimento-condominio')
};

export default empreendimentoService;