import React from "react";
import "./index.css";

export default function RecebeVideo({ conteudo, url }) {
    return (
        <>
            <video controls>
                <source src={url} type="video/mp4" />
            </video>
        </>
    );
}
