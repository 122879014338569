import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import DommusLoading from "./DommusLoading";
import styled from "styled-components";

export default function DommusLoadingArea({area}) {
	const {promiseInProgress} = usePromiseTracker({area: area});
	return (
		promiseInProgress &&
		<DommusLoading loadWrapperPosition={"relative"}/>
	);
}