import React, { useContext, useEffect, useState } from "react";
import { TicketType } from "../../types/TicketType";
import { QuestionarioPublicoType, QuestionarioType } from "../../contexts/QuestionarioContext/types";
import { Col, Form, Row } from "react-bootstrap";
import DommusToggle from "../DommusToggle";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { Controller, useForm } from "react-hook-form";
import { QrCodeLinkQuestionarioPublico } from "../QrCodeLinkQuestionarioPublico";
import MultiSelect from "react-multi-select-component";
import QuestionarioService from "../../services/QuestionarioService";
import { ChamadoContext } from "../ContextoGlobal";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./index.css";

interface GeracaoLinkQuestionarioPublicoProps {
    ticket: TicketType;
    questionario: QuestionarioType;
    questionarioPublico?: QuestionarioPublicoType,    
    closeModal: () => void;
}

type EnvioEnvolvidoFormType = {
    meio_comunicacao: any;
    proponentes: any;
    id_template: any;
    id_ticket?: number;
    id_questionario?: number;
}

const configs = {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
};

export function GeracaoLinkQuestionarioPublico({ ticket, questionario, questionarioPublico = {} as QuestionarioPublicoType, closeModal }: GeracaoLinkQuestionarioPublicoProps) {
    const { handleSubmit, control, register, errors, setError } = useForm<EnvioEnvolvidoFormType>()
    const [listaTemplate, setListaTemplate] = useState([]);
    const [questionarioPublicoCriado, setQuestionarioPublicoCriado] = useState<QuestionarioPublicoType>(questionarioPublico);
    // const { processoTicketSelecionado }: any = useContext(ChamadoContext);
    // const [proponentes, setProponentes] = useState([]);

    // useEffect(()=>{
    //     if(processoTicketSelecionado){
    //         let arrayIndex = [1, 2, 3];
    //         const listaProponentes:any = [];
    //         arrayIndex.forEach(index => {
    //             if(processoTicketSelecionado[`processo_proponente${index}`]){
    //                 listaProponentes.push({
    //                     label: processoTicketSelecionado[`proponente${index}_nome`],
    //                     value: processoTicketSelecionado[`processo_proponente${index}`] ?? null
    //                 })
    //             }
    //         });
    //         setProponentes(listaProponentes);       
    //     }
    // },[processoTicketSelecionado])

    // const handleBuscarTemplates = (meioComunicacao: string) => {
    //     if (meioComunicacao) {
    //         trackPromise(QuestionarioService.buscarTemplatesPorMeioComunicacao(meioComunicacao)).then((response: any) => {
    //             setListaTemplate(response.data);
    //         }).catch((error) => {
    //             Swal.fire(
    //                 'Ooops... Ocorreu um erro ao buscar templates.' + error,
    //                 '',
    //                 'error'
    //             )
    //         })
    //     } else {
    //         setListaTemplate([]);
    //     }
    // }

    // const handleOnSubmit = (data: EnvioEnvolvidoFormType) => {
    //     if (!data.proponentes.length) {
    //         setError("proponentes", { type: "custom", message: "Campo obrigatório!" });
    //         return;
    //     }
    //     data.proponentes = data?.proponentes?.map((item: any) => item.value);
    //     data.id_ticket = ticket.id_ticket;
    //     data.id_questionario = questionario.id_questionario;

    //     trackPromise(QuestionarioService.dispararLinkproponentes(data)).then((response) => {
    //         Swal.fire(
    //             "Pesquisas enviadas com sucesso!",
    //             '',
    //             'success'
    //         );
    //         closeModal();
    //     }).catch((error) => {
    //         Swal.fire(
    //             `Ooops... ${error?.response.data?.message ?? 'Ocorreu um erro ao enviar links para os proponentes.'}`,
    //             '',
    //             'error'
    //         )
    //     })
    // }

    const handleGerarLinkAvulso = (dados: any) => {
        Swal.fire({
            titleText: "Gerar link avulso",
            text: `Tem certeza que deseja criar o link público para o questionário "${questionario.descricao}"?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, gerar!",
            cancelButtonText: "Não",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((res) => {
            if (res.value) {
                const payload = {
                    id_ticket: ticket.id_ticket,
                    id_questionario: questionario.id_questionario,
                    sla_expira: dados?.sla_expira,
                    origem: "avulso"
                }
                trackPromise(QuestionarioService.gerarLinkPublico(payload)).then((response: any) => {
                    setQuestionarioPublicoCriado(response?.data ?? null);
                }).catch((error) => {
                    let mensagem = "";
                    if (error.response?.data?.message) {
                        mensagem = error.response?.data?.message;
                    } else {
                        mensagem = `Houve um erro ao tentar gerar link avulso. Status: ${error}`
                    }
                    Swal.fire(
                        'Ooops... ' + mensagem,
                        '',
                        'error'
                    );
                })
            }
        });
    }


    return (
        <div style={{ maxHeight: '75vh', overflowX: 'hidden', overflowY: 'auto', marginLeft: '1rem', marginRight: '1rem' }}>
            <Row>
                <Col sm={12} >
                    <DommusToggle title="Link Avulso" toggle={true}>
                        <div className="link-avulso-section">
                            <QrCodeLinkQuestionarioPublico questionarioPublico={questionarioPublicoCriado} handleGerarLinkQuestionarioPublico={handleGerarLinkAvulso} />
                        </div>
                    </DommusToggle>
                </Col>
            </Row>
            {/* {proponentes?.length ?
                <Row className="mt-1">
                    <Col sm={12}>
                        <DommusToggle title="Enviar para proponentes"  toggle={true}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className="envio-proponente-section">
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Envolvido(s):</Form.Label>
                                    <Controller
                                        control={control}
                                        name={"proponentes"}
                                        defaultValue={[]}
                                        rules={{ required: true }}
                                        as={
                                            <MultiSelect
                                                options={
                                                    proponentes.map((proponente) => proponente) as Option[]
                                                }
                                                labelledBy=""
                                                value={[]}
                                                overrideStrings={configs}
                                            />
                                        }
                                    />
                                    {errors && errors?.proponentes && <small className="form-text text-danger">Nenhum proponente selecionado.</small>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Meio Comunicação:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="meio_comunicacao"
                                        ref={register({ required: true })}
                                        onChange={(event) => {
                                            handleBuscarTemplates(event.target.value);
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="email">E-mail</option>
                                        <option value="sms">SMS</option>
                                        <option value="whatsapp">Whatsapp</option>
                                    </Form.Control>
                                    {errors && errors?.meio_comunicacao && <small className="form-text text-danger">Selecione um Meio de Comunicação.</small>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="formEmpreendimento">
                                    <Form.Label id="label-tags">Template:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="id_template"
                                        ref={register({ required: true })}
                                        disabled={!listaTemplate.length}
                                    >
                                        <option value="">Selecione</option>
                                        {listaTemplate.map((item: any, index: number) => {
                                            return <option key={index} value={item.id}>{item.nome}</option>
                                        })}
                                    </Form.Control>
                                    {errors && errors?.id_template && <small className="form-text text-danger">Selecione um Template.</small>}
                                </Form.Group>
                                <button className="btn btn-success" type="submit">
                                    <FontAwesomeIcon icon={faPaperPlane as IconProp} /> Enviar
                                </button>

                            </form>
                        </DommusToggle>
                    </Col>
                </Row>
                : <></>
            } */}
        </div>
    )
}