import React, {useEffect, useState, Suspense, lazy} from "react";
import {Switch, Route} from "react-router-dom";
import {useHistory} from "react-router-dom";
import GuService from "./services/GuService";
import Base from "./base.js";
import {trackPromise} from "react-promise-tracker";
import DommusLoading from './components/DommusLoading/DommusLoading';

export default function Routes({}) {
    const [tokenValido, setTokenValido] = useState(false);
    const telas = GuService.getLocalState("telas");
    const history = useHistory();
    const Evento = lazy(() => import('./pages/Evento'));
    const Aprovacao = lazy(() => import('./pages/Aprovacao'));
    const Chamados = lazy(() => import('./pages/Widgets/MeusChamados/chamados'));
    const Inscricoes = lazy(() => import('./pages/Inscricoes'));
    const CriarLista = lazy(() => import('./pages/CriarLista'));
    const Departamentos = lazy(() => import('./pages/Departamentos'));
    const Usuarios = lazy(() => import('./pages/Perfil'));
    const Motivos = lazy(() => import('./pages/Motivos'));
    const CriarTipoTarefa = lazy(() => import('./pages/TiposTarefa'));
    const AgendamentoVisita = lazy(() => import('./pages/AgendamentoVisita'));
    const TiposTicket = lazy(() => import('./pages/TiposTicket'));
    const Templates = lazy(() => import('./pages/Templates'));
    const RelatoriosDiversos = lazy(() => import('./pages/Relatorios/Diversos'));
    const Indicacoes = lazy(() => import('./pages/Indicacoes'));
    const SolicitacaoCriacaoAtualizacao = lazy(() => import('./pages/SolicitacaoCriacaoAtualizacao'));
    const Login = lazy(() => import('./pages/Login'));
    const Logout = lazy(() => import('./pages/Logout'));
    const GuLogin = lazy(() => import('./pages/Auth/GuLogin'));
    const Auth = lazy(() => import('./pages/Auth'));
    const Questionarios= lazy(() => import('./pages/Questionarios'));
    const Dashboards = lazy(() => import('./pages/Dashboards/index.tsx'));

    useEffect(() => {
        if (!history.location.pathname.includes("gu-login")) {
            trackPromise(valida());
        }
    }, [history.location.pathname]);


    async function valida() {
        let tokenAtual = GuService.getLocalState("token");
        if (
            GuService.getLocalState("token") != null &&
            GuService.getLocalState("idUsuario") != null
        ) {
            GuService.validaToken(tokenAtual)
                .then((res) => {
                    GuService.setLocalState("permissoes", res.data.permissoes);
                    setTokenValido(res.data.valido);
                })
                .catch((err) => {
                    GuService.redirectGU();
                });
        } else {
            GuService.redirectGU();
        }
    }

    return (
        <Switch>
         <Suspense fallback={<DommusLoading />}>

            <Route path="/login" exact component={() => <Login />}/>
            <Route path="/sair" exact component={() => <Logout />}/>
            <Route path="/gu-login/:token" exact component={() => <GuLogin />}/>
            <Route path="/auth" exact component={() => <Auth />}/>
            {tokenValido && (
                <Base
                    history={history}
                    conteudo={
                        <div style={{height: "100%"}}>
                            {telas.includes("portal_cliente_atendente") && (
                                <>
                                    <Route path="/" exact component={() => <Chamados />}/>
                                    <Route path="/eventos" exact component={() => <Evento />}/>
                                    <Route path="/aprovacao" exact component={() => <Aprovacao />}/>
                                    <Route path="/solicitacao-novo-acesso" component={() => <SolicitacaoCriacaoAtualizacao />}/>
                                    <Route path="/inscricoes" exact component={() => <Inscricoes />}/>
                                    <Route path="/criar" exact component={() => <CriarLista />}/>
                                    <Route path="/fila/:idFila" exact component={() => <Chamados/>}/>
                                    <Route path="/agenda-do-usuario" exact component={() => <AgendamentoVisita />}/>
                                    <Route path="/indicacoes" exact component={() => <Indicacoes />}/>
                                    <Route path="/relatorios/diversos" exact component={() => <RelatoriosDiversos/>}/>                                    
                                </>
                            )}
                            {telas.includes("configuracoes_gestor") && (
                                <>
                                    <Route path="/usuarios" exact component={() => <Usuarios />}/>
                                    <Route path="/motivos" exact component={() => <Motivos />}/>
                                    <Route path="/tipos-tarefa" exact component={() => <CriarTipoTarefa />}/>
                                    <Route path="/departamentos" exact component={() => <Departamentos />}/>
                                    <Route path="/tipos-ticket" exact component={() => <TiposTicket />}/>
                                    <Route path="/templates" exact component={() => <Templates />}/>
                                    <Route path="/questionarios" exact component={() => <Questionarios />}/>
                                </>
                            )}
                            {telas.includes('dashboards') && <Route path="/dashboards" exact component={() => <Dashboards />}/>}
                        </div>
                    }
                ></Base>
            )}
          </Suspense>
        </Switch>
    );
}
