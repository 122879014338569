import React, { useCallback, useMemo } from "react";
import guService from "../../services/GuService";
import ReactShadowRoot from "react-shadow-root";

function NavegadorModulos() {

    const name = useMemo(() => {
        return "dommus-navegacao-modulo";
    }, []);
    const host = useMemo(() => {
        return "https://cdn.navegacao.dommus.com.br/";
    }, [])

    const ref = useCallback((node:any)=>{
        if (name && host && node) {
            const scriptId = `micro-frontend-script-${name}`;
            const linkId = `micro-frontend-styles-${name}`;
            const vendorScriptId = `micro-frontend-vendor-script-${name}`;
            const vendorLinkId = `micro-frontend-vendor-styles-${name}`;
            const widgetsWrapper = node.parentNode;
            if (widgetsWrapper) {
                const existingScript = widgetsWrapper.getElementById(scriptId);
                const existingLink = widgetsWrapper.getElementById(linkId);
                const existingVendorScript = widgetsWrapper.getElementById(vendorScriptId);
                const existingVendorLink = widgetsWrapper.getElementById(vendorLinkId);

                const children = [];
                if (!existingVendorScript) {
                    const vendorScript = document.createElement('script');
                    vendorScript.src = `${host}/static/js/vendors.js`;
                    vendorScript.id = vendorScriptId;
                    children.push(vendorScript);
                }
                if (!existingVendorLink) {
                    const vendorLink = document.createElement('link');
                    vendorLink.href = `${host}/static/css/vendors.css`;
                    vendorLink.rel = "stylesheet";
                    vendorLink.id = vendorLinkId;
                    children.push(vendorLink);
                }
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.src = `${host}/static/js/main.js`;
                    script.id = scriptId;
                    children.push(script);
                }
                if (!existingLink) {
                    const link = document.createElement('link');
                    link.href = `${host}/static/css/styles.css`;
                    link.rel = "stylesheet";
                    link.id = linkId;
                    children.push(link);
                }

                if (children.length) {
                    const documentFragment = document.createDocumentFragment();
                    children.forEach(c => documentFragment.appendChild(c));
                    widgetsWrapper.appendChild(documentFragment);
                }
            }
        }
    },[]);

    return (
        <div id="dommus-navegacao-modulo-shadow">
            <ReactShadowRoot>
                <div
                    id="dommus-navegacao-modulo"
                    data-gu-token={guService.getLocalState('guToken')}
                    data-client={guService.getLocalState('idInquilino')}
                    data-user={guService.getLocalState('idUsuario')}
                    data-current-module={9}
                    ref={ref}
                >
                </div>
            </ReactShadowRoot>
        </div>
    )
}

NavegadorModulos.defaultProps = {
    document,
    window,
};

export default NavegadorModulos;