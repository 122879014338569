import crm from "./crm";
import { trackPromise } from "react-promise-tracker";
import swal from "sweetalert";

const ProcessoService = {
  buscarProcessosCpf: (cpf) => {
    return trackPromise(crm.get("/processo/cpf/" + cpf));
  },
  buscarBoleto: (id, idParcela, idVenda) => {
    return trackPromise(
      crm.get("/processo/" + id + "/boleto/" + idParcela + "/" + idVenda)
    );
  },
  buscarDadosProcesso: (id) => {
    return trackPromise(crm.get("/processo/" + id));
  },
  buscarDadosProponente: (id) => {
    return trackPromise(crm.get("/processo/" + id + "/proponentes"));
  },
  buscarFluxoPagamento: (id) => {
    return trackPromise(crm.get("/processo/" + id + "/pagamento"));
  },
  list: (nome) => {
    let uri = "/processo/nome";
    return crm["post"].call(crm, uri, { dados: nome });
  },
  listPorDados: (dado, tipoBuscaProcesso) => {
    let uri = "/processo/buscar-por-dados";
    var key = String(tipoBuscaProcesso).toLowerCase().replace('-','');    
    return crm["post"].call(crm, uri, { dados: { [key]: dado}});
  },
  buscarProcessoPorReferencia: (dados)=>{
      let uri = "/processo/busca-por-referencia";
      return crm["get"].call(crm, uri, {params: dados}); 
  },
  buscarUnidadesPorEmpreendimento: (idEmpreendimento)=>{
    let uri = `/processo/empreendimento/${idEmpreendimento}/unidade`;
    return crm["get"].call(crm, uri);
  }
};

export default ProcessoService;
