import crm from './crm';

const TicketTipo = {
	index:()=>{
		return crm.get('/ticket-tipo');
	},
	show:(id)=>{
		return crm.get('/ticket-tipo/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket-tipo/' + id;
        return crm['put'].call(crm, uri, {tipo: dados});
    },
	store: (dados) => {
        let uri = '/ticket-tipo';
        return crm['post'].call(crm, uri, {tipo: dados});
    },
};

export default TicketTipo;
