import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../DommusToast";
import DommusLoadingArea from "../DommusLoading/DommusLoadingArea";

export default function DommusBase64AudioPlayer({
    promiseBuscaAudio,
    base64audio,
    mimeType,
    indice,
}) {
    const [srcAudio, setSrcAudio] = useState(
        (base64audio && "data:audio/wav;base64, " + base64audio) || null
    );
    const [buscando, setBuscando] = useState(false);
    const audioRef = useRef(null);
    useEffect(() => {
        return function cleanup() {
            if (srcAudio && !base64audio && audioRef && audioRef.current) {
                audioRef.current.addEventListener("canplaythrough", (event) => {
                    audioRef.current.play();
                });
            }
            if (srcAudio && audioRef && audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, [srcAudio, audioRef.current]);

    const buscarBase64 = () => {
        setBuscando(true);
        trackPromise(
            promiseBuscaAudio()
                .then((response) => {
                    if (response.data) {
                        const novoAudio =
                            "data:" +
                            (mimeType || "audio/wav") +
                            ";base64, " +
                            response.data;
                        setSrcAudio(novoAudio);
                        setBuscando(false);
                    } else {
                        setSrcAudio("");
                        errorToast.fire({ text: "Gravação não encontrada." });
                    }
                })
                .catch((error) => console.log(error)),
            "promiseAudioArea" + indice
        );
    };

    return (
        <>
            <DommusLoadingArea
                area={"promiseAudioArea" + indice}
                fundo={"#fff"}
            />
            {srcAudio && srcAudio.length ? (
                <audio
                    className="audioGravacao"
                    controls
                    controlsList="nodownload"
                    preload="metadata"
                    ref={audioRef}
                >
                    <source src={srcAudio} type={mimeType} />
                </audio>
            ) : srcAudio === "" ? (
                <Alert variant="warning">
                    Não foi possível recuperar áudio
                </Alert>
            ) : (
                !buscando && (
                    <Button variant="outline-primary" onClick={buscarBase64}>
                        <FontAwesomeIcon
                            icon={faDownload}
                            style={{ marginRight: "0.5rem" }}
                        />
                        Buscar Áudio
                    </Button>
                )
            )}
        </>
    );
}
