import React, { useCallback, useMemo } from "react";
import "./index.css";
import { QuestionarioPublicoType, RespostaQuestionarioPublicoType } from "../../contexts/QuestionarioContext/types";
import moment from "moment";
import { TipoRespostaEnum } from "../../contexts/PerguntaContext/types";
import { celularMask, cepMask, cpfMask, formatarParaMascaraMoeda } from "../InputTextMask";
import { Alert } from "react-bootstrap";

interface VisualizacaoQuestionarioPublicoRespondidoProps {
    questionarioPublico: QuestionarioPublicoType;
}

export function VisualizacaoQuestionarioPublicoRespondido({ questionarioPublico }: VisualizacaoQuestionarioPublicoRespondidoProps) {

    const resolveBadgeStatus = useMemo(() => {
        const dataExpira = moment(questionarioPublico.criado_em).add(questionarioPublico.sla_expira, 'hours');
        const expirado = moment() > dataExpira;

        if (questionarioPublico?.data_respondido) {
            return <span className="badge-resposta badge-respondido"><strong>Respondido</strong><span>em: {moment(questionarioPublico?.data_respondido).format("DD/MM/YYYY HH:mm")}</span></span>
        } else {
            if(questionarioPublico.link_publico){
                return <span className={`badge-resposta badge-${expirado ? 'expirado' : 'aberto'}`}><strong>Não Respondido!</strong><span>{expirado ? " Expirado em: " : " Expira em: "} {dataExpira.format("DD/MM/YYYY HH:mm")}</span></span>
            }else{
                return <span className={`badge-resposta badge-error`}>Erro ao gerar Link</span>
            }
        }
    }, []);

    const handleMontarResposta = useCallback((tipoResposta:number, resposta: any) => {
        if (!resposta || resposta === "") {
            return <span style={{ color: "GrayText" }}> --- </span>;
        } else {
            switch (tipoResposta) {
                case TipoRespostaEnum.TEXTO:
                    return resposta;
                case TipoRespostaEnum.CEP:
                    return cepMask(resposta ?? "");
                case TipoRespostaEnum.DATA:
                    return moment(resposta).format("DD/MM/YYYY");
                case TipoRespostaEnum.CPF:
                    return cpfMask(resposta ?? "");
                case TipoRespostaEnum.TELEFONE:
                    return celularMask(resposta ?? "");
                case TipoRespostaEnum.MOEDA:
                    return formatarParaMascaraMoeda(resposta ?? "");
                case TipoRespostaEnum.CHECKBOX:
                    return resposta?.toString();
                case TipoRespostaEnum.SIMPLES_SELECAO:
                    return resposta?.label;
                case TipoRespostaEnum.MULTIPLA_SELECAO:
                    return resposta?.map((item: any) => item?.label ?? "").toString();
                default:
                    return "---";
            }
        }
    }, []);

    return (
        <div className="container-perguntas-questionario">
            <div className="header-perguntas-questionario">
                <div className="info-section">
                    <div className="bloco-visualizacao-questionario">
                        <label>Origem</label>
                        <span>{questionarioPublico?.origem}</span>
                    </div>
                    {questionarioPublico?.contato && 
                        <div className="bloco-visualizacao-questionario">
                            <label>Contato</label>
                            <span>{questionarioPublico?.contato}</span>
                        </div>
                    }
                </div>
                {resolveBadgeStatus}
            </div>
            <div className="perguntas-section">
                {questionarioPublico?.questionario?.perguntas ?
                    questionarioPublico?.questionario?.perguntas?.map((pergunta, index) => {
                        return (
                            <div className="pergunta-bloco" key={index}>
                                <span className="enunciado">
                                    {pergunta?.pergunta?.enunciado ?? "---"}
                                </span>
                                <span className="resposta-section">
                                    Resporta: {handleMontarResposta(pergunta.pergunta.id_tipo_resposta_pergunta, pergunta.pergunta.resposta)}
                                </span>
                            </div>
                        )
                    }) :
                    <Alert variant="warning">Nenhuma pergunta encontrada!</Alert>
                }
            </div>
        </div>
    )
}