import crm from './crm';

const TicketMotivo = {
	index:()=>{
		return crm.get('/ticket-motivo');
	},
	show:(id)=>{
		return crm.get('/ticket-motivo/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket-motivo/' + id;
        return crm['put'].call(crm, uri, {motivo: dados});
    },
	store: (dados) => {
        let uri = '/ticket-motivo';
        return crm['post'].call(crm, uri, {motivo: dados});
    },
	delete: (dados) => {
        let uri = '/ticket-motivo/'+dados.id_ticket_motivo;
        return crm['delete'].call(crm, uri);
    },
};

export default TicketMotivo;
