import React, { useMemo } from "react";
import "./index.css";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { ContextAwareToggle } from "../../../coluna_direita";
import { Icon } from "@iconify/react";
import mailSend from "@iconify-icons/bx/bx-mail-send";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import moment from "moment";

export function EmailComponent({ conteudo }) {
    const detalhes = useMemo(() => {
        return JSON.parse(conteudo?.detalhes).data ?? null;
    }, [conteudo]);

    const transformarAnchor = (node, children) => {
        if (node.tagName === "a" || node.tagName === "A") {
            return (
                <a
                    href={"https://" + node.getAttribute("href")}
                    target="_blank"
                >
                    {children}
                </a>
            );
        }
    };

    // const handleDownloadAttachment = (attachment) => {
    //     caAPI.atendimento.email.getAnexo(attachment.file_name).then((res) => {
    //         let a = document.createElement("a");
    //         a.target = "_blank";
    //         a.href = res.data.url;
    //         a.click();
    //     });
    // };

    return (
        <div className="email-section" key={conteudo.id}>
            <div className="header email-header">
                <Card>
                    <Accordion>
                        <div className="accordion_iten">
                            <ContextAwareToggle
                                as={Card.Header}
                                cardName={conteudo.id}
                                title={detalhes?.assunto}
                                eventKey={conteudo.id}
                            >
                                <Row className="align-items-center">
                                    <Col>
                                        <div className="dados-assunto">
                                            {conteudo.direcao === "enviado" ? (
                                                <Icon
                                                    className="iconeEnviadoRecebido"
                                                    icon={mailSend}
                                                />
                                            ) : (
                                                <Icon
                                                    className="iconeEnviadoRecebido"
                                                    icon={mailSend}
                                                    hFlip={true}
                                                />
                                            )}
                                            {detalhes?.assunto}
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div className="data-assunto">
                                            {moment(conteudo.criado_em).format(
                                                "DD/MM/YYYY HH:mm"
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey={conteudo.id}>
                            <div className="dadosEnvio">
                                {conteudo.direcao === "enviado" ? (
                                    <div className="dadosPara">
                                        Para: {detalhes?.to}{" "}
                                    </div>
                                ) : (
                                    <div className="dadosDe">
                                        De: {detalhes?.from}
                                    </div>
                                )}
                                {detalhes?.cc ? (
                                    <div className="dadosCopiaCortesia">
                                        CC:{" "}
                                        <span className="emailCopiaCortesia">
                                            {" "}
                                            {detalhes?.cc}{" "}
                                        </span>{" "}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {detalhes?.bcc ? (
                                    <div className="dadosCopiaCortesiaOculta">
                                        CCO:{" "}
                                        <span className="emailCopiaCortesiaOculta">
                                            {detalhes?.bcc}{" "}
                                        </span>{" "}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            </div>
            <div className="body" style={{ overflow: "hidden" }}>
                <Interweave
                    content={conteudo.conteudo.substring(0, 1000)}
                    transform={transformarAnchor}
                    matchers={[
                        new UrlMatcher("url", {
                            validateTLD: false,
                        }),
                    ]}
                />
                {/* {detalhes.attachments && (
                        <div style={{ backgroundColor: "#ebebeb" }}>
                            {detalhes.attachments.map(function (
                                attachment,
                                indice
                            ) {
                                return (
                                    <span
                                        key={indice}
                                        onClick={() =>
                                            // handleDownloadAttachment(
                                            //     attachment
                                            // )
                                            {}
                                        }
                                        style={{
                                            padding: "15px",
                                            cursor: "pointer",
                                            fontSize: "0.8em",
                                        }}
                                    >
                                        {attachment.file_name}
                                    </span>
                                );
                            })}
                        </div>
                    )} */}
            </div>
        </div>
    );
}
