import React, { useContext } from 'react';
import MenuTopo from './components/MenuTopo';
import MenuLateral from './components/MenuLateral';
import { isDesktop } from 'react-device-detect';
import { ConfiguracaoContext } from './contexts/ConfiguracaoContext';

export default function Base({conteudo, history}) {
	const {openMenuMobile} = useContext(ConfiguracaoContext);
	return (
		<div>
			<MenuTopo history={history}/>
			<div className="wrapper">
				<div className="sidebar">
					{(openMenuMobile || isDesktop) && 
						<MenuLateral history={history}/>
					}
				</div>
				<div className="content">
					{conteudo}
				</div>
			</div>
		</div>

	);
}