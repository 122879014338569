import React from 'react';
import "./index.css";

export default function RecebeImages({ conteudo, url }) {
  return <>
    {url &&
      <div>
        <div>
          <img src={url} className="mensagemImagem"/>
        </div>
        <div className="textoImagem">
          {conteudo}
        </div>
      </div>
    }
  </>
}
