import crm from "./crm";

const ticketService = {
  index: () => {
    return crm.get("/ticket-conclusao-tipo");
  },
  store: (dados, idTicket) => {
    let uri = `/ticket/${idTicket}/conclusao`;
    return crm["post"].call(crm, uri, { conclusao: dados });
  },
};

export default ticketService;
