import React from "react";
import "./index.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface TagsOportunidadeProps {
    items: any[];
    maxItems?: number;
    useEllipse?: boolean;
}

export function DommusBadgesResposaveis({ items, maxItems=10, useEllipse=true }: TagsOportunidadeProps) {
    return (
        <div className="badges-section">
            {items && items.map((item, index) => {
                if (index <= (maxItems - 1) ) {
                    return (
                        <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                                <Tooltip id={String(`${item.value}-${item.label}`)}>
                                    {item.label}
                                </Tooltip>
                            }
                        >
                            <span className="item-badge">
                                {item.label}
                            </span>
                        </OverlayTrigger>
                    );
                }
            })
            }
            {items && items.length > maxItems ?
                <OverlayTrigger
                    key="itemsAgrupadas"
                    placement="top"
                    overlay={
                        <Tooltip id={String("items-agrupadas")}>
                            {items && items.map((item, index) => {
                                if (index >= maxItems) {
                                    return item?.label + (index !== items.length - 1 ? " \u{2022} " : "");
                                }
                            })}
                        </Tooltip>
                    }
                >
                    <span className="item-agrupada">
                        + {items.length - maxItems}
                    </span>
                </OverlayTrigger>
                : <></>
            }            
        </div>
    )
}
