const ValidaTipoArquivo = {
  verificaTipoArquivo: (file) => {
    let retorno = false;
    if (Array.isArray(file)) {
      return ValidaTipoArquivo.validaArquivosArray(file)
    } else if(typeof file == 'object' && Object.values(file).length) {
      return ValidaTipoArquivo.validaArquivosArray(Object.values(file))
    } else if(file) {
      const nameSplitado = file.name?.split('.').at(-1)
      const tipoSplitado = file.type?.split('/').at(-1)
      retorno = nameSplitado === tipoSplitado  || nameSplitado === 'jpg'
    }
    return retorno;
  },

  validaArquivosArray(arquivos) {
    let valido = null
    for (let i = 0; i < arquivos.length; i++) {
      const nameSplitado = arquivos[i].name?.split('.').at(-1)
      const tipoSplitado = arquivos[i].type?.split('/').at(-1)
      valido = [true,null].includes(valido) && (nameSplitado === tipoSplitado || nameSplitado === 'jpg') ? true : false
    }
    return valido
  }
}

export default ValidaTipoArquivo;