import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import helper from "../../../../helpers/format";
import ticketService from "../../../../services/TicketService";
import tiposTarefaService from "../../../../services/TiposTarefaService";
import MultiSelect from "react-multi-select-component";
import DommusTabelaPaginada from "../../../DommusTabelaPaginada";
import swal from "sweetalert";
import Swal from "sweetalert2";
import DommusToggle from "../../../DommusToggle";
import Alert from "react-bootstrap/Alert";
import "./index.css";
import _ from "lodash";

import tarefasService from "../../../../services/TarefasService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { Icon } from "@iconify/react";

export default function NovaTarefa({
  dataTarefa,
  cor,
  setEvents,
  close,
  disabled,
  chamadoSelecionado,
  disabledChamado,
  setRefresh,
  refresh,
  props,
  openByAgendaFlag=false
}) {
  const [titulo, setTitulo] = useState(props ? props.title : "");
  const [detalhes, setDetalhes] = useState(props ? props.detalhes : "");
  const [vezes, setVezes] = useState(props ? props.repetir : 1);
  const [rows, setRows] = useState([]);
  const [intervalo, setIntervalo] = useState({
    value: 1,
    label: "A cada semana",
  });
  const [data, setData] = useState(
    props ? props.start : dataTarefa ? dataTarefa.start : ""
  );
  const [dataInicio, setDataInicio] = useState(
    props ? props.start : dataTarefa ? dataTarefa.start : ""
  );
  const [dataFim, setDataFim] = useState(
    props ? props.end : dataTarefa ? dataTarefa.end : ""
  );
  const [errorTitulo, setErroTitulo] = useState(false);
  const [erroTarefa, setErroTarefa] = useState(false);
  const [erroData, setErroData] = useState(false);
  const [erroDataInicio, setErroDataInicio] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [destrava, setDestrava] = useState(true);
  const [nomeProponente, setNomeProponente] = useState("")
  const [listaChamados, setListaChamados] = useState([]);
  const [atribuidos, setAtribuidos] = useState(
    props ? montaResponsaveisSelecionados(props) : []
  );
  const [departamento, setDepartamento] = useState([]);
  const [chamado, setChamado] = useState(
    chamadoSelecionado ? montaChamadoSelecionado() : []
  );
  const [idTicket, setIdTicket] = useState("");
  const [tiposTarefa, setTiposTarefa] = useState(
    props ? montaTipoSelecionado(props.tiposTarefa) : []
  );
  const [listaTarefas, setListaTarefas] = useState([]);
  const [idTipoTarefa, setIdTipoTarefa] = useState("");

  const [listaAtribuir, setListaAtribuir] = useState([]);
  const [valueSelecionado, setValueSelecionado] = useState(
    props ? montaResponsaveisSelecionados(props) : []
  );
  const [notificar, setNotificar] = useState(false);

  const { handleSubmit, register } = useForm();

  useEffect(() => {
    trackPromise(tiposTarefaService.index()
      .then((res) => {
        let listaTipo = [];
        Object.values(res.data).forEach((item) => {
          listaTipo.push({
            value: item.id_tipo_tarefa,
            label: item.descricao,
          });
        });
        setListaTarefas(listaTipo);
        criaSelectAtribuido(chamado)
      })
    );
  }, []);

  function verificaError() {
    if (titulo === "" || titulo == null) {
      setErroTitulo(true);
    } else {
      setErroTitulo(false);
      if (idTipoTarefa === "" || idTipoTarefa == null) {
        setErroTarefa(true);
      } else {
        setErroTarefa(false);
        if (data === "" || data == null) {
          setErroData(true);
        } else {
          setErroData(false);
          setDestrava(false);
          if (dataInicio === "" || dataInicio == null) {
            setErroDataInicio(true);
          } else {
            setErroDataInicio(false);
            setDestrava(false);
            if (dataFim === "" || dataFim == null) {
              setErroDataFim(true);
            } else {
              setErroDataFim(false);
              setDestrava(false);
            }
          }
        }
      }
    }
  }

  function montaResponsaveisSelecionados(props) {
    let departamentosRetorno = [];
    let usuariosRetorno = [];

    if (props.departamentos_responsaveis) {
      props.departamentos_responsaveis.map((item) => {
        departamentosRetorno.push(item);
      });
    } else {
      departamentosRetorno = [];
    }
    if (props.usuarios_responsaveis) {
      props.usuarios_responsaveis.map((item) => {
        usuariosRetorno.push(item);
      });
    } else {
      usuariosRetorno = [];
    }

    return departamentosRetorno.concat(usuariosRetorno);
  }

  function montaTipoSelecionado(props) {
    if (props) {
      let arr = [];
      Object.values(props).forEach((item) => {
        arr.push({
          value: props.id_tipo_tarefa,
          label: props.descricao,
        });
      });

      return arr;
    } else {
      return [];
    }
  }

  function criaTarefa() {
    let events = [];
    let dados = {
      id_tipo_tarefa: idTipoTarefa,
      id_ticket: idTicket,
      titulo: titulo,
      detalhes: detalhes,
      data_inicio: helper.formataMysqlDateHour(
        moment(dataInicio).format("DD/MM/YYYY HH:mm")
      ),
      data_fim: helper.formataMysqlDateHour(
        moment(dataFim).format("DD/MM/YYYY HH:mm")
      ),
      repetir: vezes,
      realizada: 0,
      usuarios_responsaveis: atribuidos,
      departamentos_responsaveis: departamento,
    };
    trackPromise(
      tarefasService.store(dados).then(
        (res) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Tarefa salva com sucesso!",
            icon: "success",
          });
          setRefresh(!refresh);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Erro ao criar tarefa!",
            icon: "error",
          });
        }
      )
    );
    events.push(dados);
    setEvents(events);
    close();
  }

  function editaTarefa(id) {
    let events = [];
    let dados = {
      id_tipo_tarefa: idTipoTarefa,
      id_ticket: idTicket,
      titulo: titulo,
      detalhes: detalhes,
      data_inicio: helper.formataMysqlDateHour(
        moment(dataInicio).format("DD/MM/YYYY HH:mm")
      ),
      data_fim: helper.formataMysqlDateHour(
        moment(dataFim).format("DD/MM/YYYY HH:mm")
      ),
      repetir: vezes,
      usuarios_responsaveis: atribuidos,
      departamentos_responsaveis: departamento,
    };
    trackPromise(
      tarefasService.update(id, dados).then(
        (res) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Tarefa editada com sucesso!",
            icon: "success",
          });
          setRefresh(!refresh);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Erro ao editar tarefa!",
            icon: "error",
          });
        }
      )
    );
    events.push(dados);
    close();
  }

  const configs = {
    selectSomeItems: "Selecionar",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
  };

  function montaChamadoSelecionado() {
    let teste = [];
    let teste2;
    teste.push(chamadoSelecionado);
    teste.map((item) => {
      teste2 = {
        value: item.id_ticket,
        label:
          "SS-" + helper.zeroPad(item.id_ticket, 5) + " " + item.proponentes
            ? "SS-" +
              helper.zeroPad(item.id_ticket, 5) +
              " " +
              item.proponentes?.proponente1
            : "SS-" + helper.zeroPad(item.id_ticket, 5) + " " + "Não Atribuído",
        departamentos_acompanhamento: item.departamentos_acompanhamento,
        departamentos_aprovacao: item.departamentos_aprovacao,
        departamentos_autorizacao: item.departamentos_autorizacao,
        departamentos_execucao: item.departamentos_execucao,
        responsaveis_aprovacao: item.responsaveis_aprovacao,
        responsaveis_autorizacao: item.responsaveis_autorizacao,
        responsaveis_execucao: item.responsaveis_execucao,
        usuarios_acompanhamento: item.usuarios_acompanhamento,
      };
    });
    return teste2;
  }

  function handleCriaTarefa() {
    let events = [];
    let dados = {
      id_tipo_tarefa: idTipoTarefa,
      id_ticket: chamado.value,
      titulo: titulo,
      detalhes: detalhes,
      data_inicio: helper.formataMysqlDateHour(
        moment(data).format("DD/MM/YYYY HH:mm")
      ),
      data_fim: helper.formataMysqlDateHour(
        moment(data).format("DD/MM/YYYY HH:mm")
      ),
      repetir: vezes,
      usuarios_responsaveis: atribuidos,
      departamentos_responsaveis: departamento,
    };

    trackPromise(
      tarefasService.store(dados).then(
        (res) => {
          Swal.fire({
            titleText: "Sucesso!",
            text: "Tarefa salva com sucesso!",
            icon: "success",
          });
          setRefresh(!refresh);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Erro ao criar tarefa!",
            icon: "error",
          });
        }
      )
    );
    events.push(dados);
    close();
  }

  function criaSelectAtribuido(chamado) {
    let aprovacao = [];
    let execucao = [];
    let autorizacao = [];
    let acompanhamento = [];
    let departamentoAcompanhamento = [];
    let departamentoAprovacao = [];
    let departamentoAutorizacao = [];
    let departamentoExecucao = [];
    let ArrayFiltrado = [];
    let arr = [];

    JSON.parse(
      chamado.departamentos_execucao == null
        ? "[]"
        : chamado.departamentos_execucao
    ).forEach((item) => {
      departamentoExecucao.push({
        value: "dep-" + item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.departamentos_acompanhamento == null
        ? "[]"
        : chamado.departamentos_acompanhamento
    ).forEach((item) => {
      departamentoAcompanhamento.push({
        value: "dep-" + item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.departamentos_aprovacao == null
        ? "[]"
        : chamado.departamentos_aprovacao
    ).forEach((item) => {
      departamentoAprovacao.push({
        value: "dep-" + item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.departamentos_autorizacao == null
        ? "[]"
        : chamado.departamentos_autorizacao
    ).forEach((item) => {
      departamentoAutorizacao.push({
        value: "dep-" + item.value,
        label: item.label,
      });
    });

    JSON.parse(
      chamado.responsaveis_execucao == null
        ? "[]"
        : chamado.responsaveis_execucao
    ).forEach((item) => {
      execucao.push({
        value: item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.responsaveis_aprovacao == null
        ? "[]"
        : chamado.responsaveis_aprovacao
    ).forEach((item) => {
      aprovacao.push({
        value: item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.responsaveis_autorizacao == null
        ? "[]"
        : chamado.responsaveis_autorizacao
    ).forEach((item) => {
      autorizacao.push({
        value: item.value,
        label: item.label,
      });
    });
    JSON.parse(
      chamado.usuarios_acompanhamento == null
        ? "[]"
        : chamado.usuarios_acompanhamento
    ).forEach((item) => {
      acompanhamento.push({
        value: item.value,
        label: item.label,
      });
    });

    let arrayConcatenado = aprovacao.concat(
      execucao,
      autorizacao,
      acompanhamento,
      departamentoAcompanhamento,
      departamentoAutorizacao,
      departamentoAprovacao,
      departamentoExecucao
    );
    arrayConcatenado.filter((item, index) => {
      ArrayFiltrado.push(item.value);
      if (ArrayFiltrado.indexOf(item.value) == index) {
        arr.push(item);
      }
    });

    setListaAtribuir(arr);
  }

  function onChangeResponsavel(value) {
    verificaRegxDepartamento(value);
  }

  function verificaRegxDepartamento(responsavel) {
    let arrDep = [];
    let arrRes = [];
    if (responsavel) {
      responsavel.map((item) => {
        if (item.label.match(/(Departamento: +[a-zA-Z0-9_.+-])/gm)) {
          arrDep.push({
            value: parseInt(item.value.split("-")[1]),
            label: item.label,
          });
        } else {
          arrRes.push(item);
        }
      });
    } else {
      return null;
    }
    setDepartamento(arrDep);
    setAtribuidos(arrRes);
    setValueSelecionado(arrDep.concat(arrRes));
  }

  function verificaDefaultValue(responsavel, departamento) {
    let arrDefault = [];
    if (
      responsavel != null &&
      responsavel.length > 0 &&
      helper.isJson(responsavel)
    ) {
      JSON.parse(responsavel).map((item) => {
        arrDefault.push(item);
      });
    }

    if (
      departamento != null &&
      departamento.length > 0 &&
      helper.isJson(departamento)
    ) {
      JSON.parse(departamento).map((item) => {
        arrDefault.push(item);
      });
    }
    setValueSelecionado(arrDefault);
    return arrDefault;
  }

  const thead = (
    <tr style={{ justifyContent: "center" }}>
      <th style={{ width: "35%" }}>Data/Hora</th>
      <th style={{ width: "65%" }}>Evento/Responsavel</th>
    </tr>
  );

  function carregaLista() {
    let filtro = {
      data_inicio: helper.formataMysql(moment(data).format("DD/MM/YYYY")),
      data_fim: helper.formataMysql(moment(dataFim).format("DD/MM/YYYY")),
      usuarios_responsaveis: atribuidos,
      departamentos_responsaveis: departamento,
    };
    trackPromise(
      tarefasService.index(filtro).then(
        (response) => {
          const linhas = response.data.map((item, index) => (
            <tr key={item.id_tarefa}>
              <td>
                <p
                  style={{
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {moment(item.data_inicio).format("DD/MM/YYYY")}
                  <br />
                  <p
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      marginBottom: 0,
                    }}
                  >
                    {moment(item.data_inicio).format("HH:MM")}
                  </p>
                </p>
              </td>
              <td>
                <p style={{ marginBottom: 5 }}>{item.titulo}</p>
                {item.departamentos_responsaveis ? (
                  <p style={{ marginBottom: 0 }}>
                    {item.departamentos_responsaveis.map((item) => {
                      return item.label;
                    })}
                  </p>
                ) : (
                  <p style={{ marginBottom: 0 }}>
                    {item.usuarios_responsaveis.map((item) => {
                      return item.label;
                    })}
                  </p>
                )}
              </td>
            </tr>
          ));
          setRows(linhas);
        },
        (error) => {
          swal(
            "Ooops...",
            "Ocorreu um erro ao buscar os tickets: " + error.data,
            "error"
          );
        }
      )
    );
  }
  useEffect(() => {
    if (
      (atribuidos.length > 0 && data !== "") ||
      (valueSelecionado.length > 0 && data !== "") ||
      (valueSelecionado.length > 0 && dataInicio !== "") ||
      (atribuidos.length > 0 && dataInicio !== "")
    ) {
      carregaLista();
    } else {
      return;
    }
  }, [atribuidos, dataInicio, data]);

  function handleSetOptionsChamados() {
    trackPromise(ticketService.index({ nome_proponente: nomeProponente }, 100, 1))
      .then(res => {
        let chamadosList = []
        Object.values(res.data.tickets).map((item) => {
          chamadosList.push({
            value: item.id_ticket,
            label: resolveNomeDoLabelDosChamados(item),
            departamentos_acompanhamento: item.departamentos_acompanhamento || [],
            departamentos_aprovacao: item.departamentos_aprovacao || [],
            departamentos_autorizacao: item.departamentos_autorizacao || [],
            departamentos_execucao: item.departamentos_execucao || [],
            responsaveis_aprovacao: item.responsaveis_aprovacao || [],
            responsaveis_autorizacao: item.responsaveis_autorizacao || [],
            responsaveis_execucao: item.responsaveis_execucao || [],
            usuarios_acompanhamento: item.usuarios_acompanhamento || [],
          });
        });
        setListaChamados(chamadosList)
      })
  }

  function resolveNomeDoLabelDosChamados(item) {
    return item.proponentes?.proponente1 ? 
      "SS-" + helper.zeroPad(item.id_ticket, 5) + " " + item.proponentes.proponente1 : 
      "SS-" + helper.zeroPad(item.id_ticket, 5) + " " + "Não Atribuído"
  }

  return (
    <div className="Container">
      <ReactTooltip/>
      <div
        className={
          (atribuidos.length > 0 && data !== "") ||
          (valueSelecionado.length > 0 && data !== "") ||
          (valueSelecionado.length > 0 && dataInicio !== "") ||
          (atribuidos.length > 0 && dataInicio !== "")
            ? "ContainerEsquerdo"
            : "ContainerEsquerdoEsticar"
        }
      >
        <Form className="novaIndicacaoForm" onSubmit={handleSubmit(criaTarefa)}>
          <Row style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20 }}>
            <DommusToggle title={"Dados da tarefa"} toggle={true}>
              <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                <Form.Group as={Col} controlId="formAssunto">
                  <Form.Label className="inputLabel">
                    Título: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    onBlur={() => verificaError()}
                    name="titulo"
                    type="text"
                    value={titulo}
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                  {errorTitulo ? (
                    <p className="campoError">Campo Obrigatório</p>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="formAssunto">
                  <Form.Label className="inputLabel">
                    Tipos de tarefa: <DommusRequiredSpan />
                  </Form.Label>
                  <Select
                    onBlur={() => verificaError()}
                    name="chamados"
                    placeholder="Selecionar"
                    value={tiposTarefa}
                    onChange={(event) => {
                      setTiposTarefa(event);
                      setIdTipoTarefa(event.value);
                    }}
                    isSearchable={true}
                    options={listaTarefas}
                  />
                  {erroTarefa ? (
                    <p className="campoError">Campo Obrigatório</p>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Row>
              <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                <Form.Group as={Col} controlId="formAssunto">
                  <Form.Label className="inputLabel">Chamados:</Form.Label>
                  <Row className="justify-content-between">
                    {listaChamados.length > 0 || !openByAgendaFlag ? (
                      <>
                        <Select
                          className={!openByAgendaFlag ? "col" : "col-lg-9 col-8"}
                          isDisabled={disabledChamado}
                          name="chamados"
                          placeholder="Selecionar"
                          value={chamado}
                          onChange={(event) => {
                            criaSelectAtribuido(event);
                            setChamado(event);
                            setIdTicket(event.value);
                          }}
                          isSearchable={true}
                          options={listaChamados}                          
                        />
                        {openByAgendaFlag && (
                          <Button 
                            variant="info" 
                            type="button" 
                            className="mr-2 px-4" 
                            onClick={() => {
                              setListaChamados([])
                              setChamado([])
                            }} 
                            data-tip="Voltar para busca de chamados"
                          >
                            <Icon icon="akar-icons:arrow-right-left" color="white" inline={true}/>
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Form.Control
                          className="col-lg-9 col-8 ml-3"
                          type="text"
                          placeholder="Digite o nome do proponente."
                          value={nomeProponente}
                          onChange={(event) => setNomeProponente(event.target.value)}
                        />
                        <Button variant="primary" type="button" className="mr-2 px-4" onClick={() => handleSetOptionsChamados()} data-tip="Buscar Chamados">
                          <FontAwesomeIcon icon={faSearch}/>
                        </Button>
                      </>
                    )}
                  </Row>
                </Form.Group>
                <div
                  style={{
                    marginBottom: "1rem",
                    width: "48%",
                    marginLeft: 15,
                    maxWidth: "48%",
                    marginRight: 10,
                  }}
                >
                  <Form.Label className="inputLabel">
                    Atribuir responsáveis:
                  </Form.Label>
                  <MultiSelect
                    name="atribuir"
                    options={listaAtribuir}
                    onChange={(event) => {
                      onChangeResponsavel(event);
                    }}
                    value={valueSelecionado}
                    tabIndex={0}
                    overrideStrings={configs}
                  />
                </div>
              </Row>
              <Form.Group as={Col} controlId="formAssunto">
                <Form.Label className="inputLabel">Detalhes:</Form.Label>
                <textarea
                  name="detalhes"
                  className="form-control"
                  rows={4}
                  multiple
                  onChange={(event) => setDetalhes(event.target.value)}
                  value={detalhes}
                  type="text"
                />
              </Form.Group>
              {dataTarefa ? (
                dataTarefa.bounds != undefined ? (
                  <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <Form.Group as={Col} controlId="formAssunto">
                      <Form.Label className="inputLabel">
                        Data Inicio: <DommusRequiredSpan />
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        onBlur={() => verificaError()}
                        name="dataInicio"
                        // disabled={true}
                        value={moment(dataInicio).format("yyyy-MM-DDTHH:mm")}
                        onChange={(event) => {
                          setDataInicio(event.target.value);
                          setData(event.target.value);
                        }}
                      />
                      {erroDataInicio ? (
                        <p className="campoError">Campo Obrigatório</p>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAssunto">
                      <Form.Label className="inputLabel">
                        Data Fim: <DommusRequiredSpan />
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="dataFim"
                        onBlur={() => verificaError()}
                        // disabled={true}
                        value={moment(dataFim).format("yyyy-MM-DDTHH:mm")}
                        onChange={(event) => setDataFim(event.target.value)}
                      />
                      {erroDataFim ? (
                        <p className="campoError">Campo Obrigatório</p>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </Row>
                ) : (
                  <Form.Group as={Col} controlId="formAssunto">
                    <Form.Label className="inputLabel">
                      Data: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="dataInicio"
                      onBlur={() => verificaError()}
                      // disabled={disabled}
                      value={moment(data).format("yyyy-MM-DDTHH:mm")}
                      onChange={(event) => {
                        setData(event.target.value);
                        setDataInicio(event.target.value);
                      }}
                    />
                    {erroData ? (
                      <p className="campoError">Campo Obrigatório</p>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                )
              ) : (
                <Form.Group as={Col} controlId="formAssunto">
                  <Form.Label className="inputLabel">
                    Data: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="data"
                    onBlur={() => verificaError()}
                    // disabled={disabled}
                    value={moment(data).format("yyyy-MM-DDTHH:mm")}
                    onChange={(event) => {
                      setData(event.target.value);
                      setDataInicio(event.target.value);
                    }}
                  />
                  {erroData ? (
                    <p className="campoError">Campo Obrigatório</p>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              )}
            </DommusToggle>
          </Row>
          <Row style={{ paddingLeft: 30, paddingRight: 30 }}>
            <DommusToggle title={"Repetir"} toggle={false}>
              <Col lg={3}>
                <Form.Group controlId="formAssunto">
                  <Form.Label className="inputLabel">
                    Durante quantas semanas:
                  </Form.Label>
                  <Form.Control
                    name="vezes"
                    type="number"
                    value={vezes}
                    onChange={(event) => setVezes(event.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg={9}>
            <Form.Group controlId="formAssunto">
              <Form.Label style={{ marginTop: 17 }}></Form.Label>
              <Select
                name="intervalo"
                placeholder="Selecionar"
                isClearable
                isDisabled
                options={[{ value: 1, label: "A cada semana" }]}
                value={intervalo}
                onChange={(event) => setIntervalo(event)}
              />
            </Form.Group>
          </Col> */}
            </DommusToggle>
          </Row>
          {/* <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Form.Group as={Col} controlId="formAssunto">
            <Form.Label>Deseja receber notificações?</Form.Label>
            <Select
              name="notificacoes"
              placeholder="Selecionar"
              isClearable
              options={[
                { value: 0, label: "Não" },
                { value: 1, label: "Sim" },
              ]}
              onChange={(event) => setNotificar(event.value)}
            />
          </Form.Group>
        </Row> */}
          {disabledChamado ? (
            <></>
          ) : (
            <Form.Group as={Col} controlId="formSubmit">
              <button
                disabled={destrava}
                className="btn btn-primary"
                type="submit"
                style={{
                  backgroundColor: cor,
                  alignSelf: "center",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Salvar Tarefa
              </button>
            </Form.Group>
          )}
        </Form>

        {disabledChamado ? (
          props ? (
            <Form.Group as={Col}>
              <button
                className="btn btn-primary"
                disabled={destrava}
                onClick={(e) => {
                  editaTarefa(props.id);
                }}
                style={{
                  backgroundColor: cor,
                  alignSelf: "center",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Editar Tarefa
              </button>
            </Form.Group>
          ) : (
            <Form.Group as={Col}>
              <button
                disabled={destrava}
                className="btn btn-primary"
                onClick={() => {
                  handleCriaTarefa();
                }}
                style={{
                  backgroundColor: cor,
                  alignSelf: "center",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Salvar Tarefa
              </button>
            </Form.Group>
          )
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          (atribuidos.length > 0 && data !== "") ||
          (valueSelecionado.length > 0 && data !== "") ||
          (valueSelecionado.length > 0 && dataInicio !== "") ||
          (atribuidos.length > 0 && dataInicio !== "")
            ? "ContainerDireito"
            : "ContainerDireitoEsconder"
        }
      >
        <DommusToggle
          title={`Agenda do dia ${
            data !== ""
              ? moment(data).format("DD/MM/YYYY")
              : moment(dataInicio).format("DD/MM/YYYY")
          } `}
          toggle={true}
        >
          {rows.length > 0 ? (
            <DommusTabelaPaginada
              linhasCabecalho={thead}
              optionsTabela={{}}
              linhasCorpo={rows}
            />
          ) : (
            <Alert variant="warning">Nenhuma tarefa a ser exibida.</Alert>
          )}
        </DommusToggle>
      </div>
    </div>
  );
}
