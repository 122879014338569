import React, { useEffect, useState } from "react";
import { faCheck, faExclamationTriangle, faFilePdf, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Form, Row } from "react-bootstrap";
import './styles.css'
import ReactTooltip from "react-tooltip";
import { useForm } from "react-hook-form";
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import DommusToggle from "../DommusToggle";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import {isMobile} from 'react-device-detect';
import Anexos from "../../pages/Widgets/MeusChamados/Chamado/Chat/Anexos";
import ArquivoGaleiraService from "../../services/ArquivoGaleriaService";
import convertFilesToBase64 from "../../helpers/ConvertFilesToBase64";
import AnexoIcone from "../../pages/Widgets/MeusChamados/Chamado/Chat/Anexos/AnexoIcone";
import { DommusTextAreaResize } from "../DommusTextareaResize";

export function Galeria({ tipo, definirEstadoModal, enviarArquivos, mensagem, setMensagem }) {
    const { register, setValue, handleSubmit, getValues } = useForm()
    const [toggleEnviarArquivosAberto, setToggleEnviarArquivosAberto] = useState(true)
    const [toggleSalvarArquivosAberto, setToggleSalvarArquivosAberto] = useState(true)
    const [atualizarTooltip, setAtualizarTooltip] = useState(false)
    const [arquivos, setArquivos] = useState([])
    const [arquivosSalvar, setArquivosSalvar] = useState([])

    useEffect(() => {
        trackPromise(ArquivoGaleiraService.buscar({ tipo }))
            .then((response) => setArquivos(response.data))
            .catch(error => {
                console.log(error)
                console.log(error?.response?.data)
                Swal.fire('Ooops...', 'Houve um erro ao tentar buscar os arquivos.', 'error')
            })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [atualizarTooltip])

    function marcarDesmarcarArquivo(idArquivo) {
        const nome = resolverNomeFormulario(idArquivo)
        setValue(nome, !getValues(nome))
        setAtualizarTooltip(atualizarTooltip => !atualizarTooltip)
    }

    function resolverNomeFormulario(idArquivo) {
        return `${tipo}-${idArquivo}`
    }

    async function converterArquivos(arquivos){
        if(arquivosSalvar.length){
            return await convertFilesToBase64.convertFileToBase64(arquivos);
        }else{
            return [];
        }        
    }

    async function salvarImagens() {
        if(!arquivosSalvar.length) {
            Swal.fire('Nenhum arquivo selecionado!', 'Selecione pelo o menos um arquivo antes de salvar.', 'warning')
            return
        }
        const arquivosConvertidos = await converterArquivos(arquivosSalvar);        
        const arquivosTratados = arquivosConvertidos.map(arquivo => {
            arquivo.extensao = arquivo.tipo
            arquivo.tipo = tipo

            return arquivo
        })
        ArquivoGaleiraService.salvar(arquivosTratados)
            .then((response) => {
                definirEstadoModal(false)
                setArquivosSalvar([])
                Swal.fire('Sucesso!', 'Arquivos salvos com sucesso.', 'success').then(() => definirEstadoModal(true))
            }).catch(error => {
                console.log(error)
                console.log(error?.response?.data)
                Swal.fire('Ooops...', 'Houve um erro ao tentar salvar os arquivos.', 'error')
            })
    }

    function enviarArquivosSelecionados(arquivosSelecionados) {
        const arquivosSelecionadosFiltrados = Object.entries(arquivosSelecionados).filter(([nome, selecionado]) => selecionado == true);
        const idsArquivosSelecionados = Object.values(arquivosSelecionadosFiltrados).map(arquivo => Number(arquivo[0].split('-')[1]))
        const arquivosSelecionadosTratados = arquivos.filter(arquivo => idsArquivosSelecionados.includes(arquivo.id_arquivo_galeria))
        if (!arquivosSelecionadosTratados.length && !mensagem.length) {            
            Swal.fire({
                titleText: "Atenção",
                text: `${tipo == 'imagem' ? 'Nenhuma imagem selecionada' : 'Nenhum anexo selecionado'}`,
                icon: 'warning'
            });
            return;
        }
        enviarArquivos(arquivosSelecionadosTratados)
    }

    function excluirArquivo(idArquivo) {
        Swal.fire({
            titleText: "Deseja excluir o arquivo?",
            text: "Confime a realização da ação.",
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não, mantenha.",
            customClass: {
            confirmButton: "btn btn-primary mr-3",
            cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (!result.isConfirmed) {
                return
            }
            trackPromise(ArquivoGaleiraService.excluir(idArquivo))
                .then(() => {
                    definirEstadoModal(false)
                    setArquivosSalvar([])
                    Swal.fire('Sucesso!', 'Arquivo excluído com sucesso.', 'success').then(() => definirEstadoModal(true))
                }).catch(error => {
                    console.log(error)
                    console.log(error?.response?.data)
                    Swal.fire('Ooops...', 'Houve um erro ao tentar excluir o arquivo.', 'error')
                })
        })
    }

    function Imagem({ arquivo }) {
        let nameItem = resolverNomeFormulario(arquivo.id_arquivo_galeria);
        return (
            <div className="image-checkbox">
                <input type="checkbox" 
                        className="custom-control-input" 
                        id={nameItem}
                        {...register(nameItem)}                 
                />
                <label className={`image-section ${getValues(nameItem) ? "item-selected" : ""}`} htmlFor={nameItem}>
                    {getValues(nameItem) &&
                        <span className="item-selecionado">
                            <FontAwesomeIcon icon={faCheck}/>
                        </span>
                    }
                    <span className="btn-excluir-item" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        excluirArquivo(arquivo.id_arquivo_galeria);
                    }}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </span>
                    <img className="image-item" src={arquivo.link} alt="Arquivo para enviar"></img>
                </label>
            </div>           
        )
    }

    function Anexo({ arquivo }) {
        let nameItem = resolverNomeFormulario(arquivo.id_arquivo_galeria);
        return (
            <div className="cardArquivo card" style={{cursor: 'pointer'}}>
                {getValues(nameItem) &&
                    <span className="item-selecionado">
                        <FontAwesomeIcon icon={faCheck}/>
                    </span>
                }
                <Row className="justify-content-end px-3" data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_galeria)) ? 'Desconsiderar' : 'Selecionar'} Anexo`}>
                    <input type="checkbox" 
                            className="custom-control-input" 
                            id={nameItem}
                            {...register(nameItem)}                 
                    />                    
                </Row>
                <Row className="my-3 justify-content-center">
                    <AnexoIcone anexo={arquivo?.nome?.split('.').pop()} style={{ fontSize: "5rem" }} />
                </Row>
                <Row className="justify-content-center" style={{ fontSize: '0.8rem', minHeight: '5vh' }} data-tip={arquivo.nome}>
                    {formatarNomeArquivo(arquivo.nome) || 'Anexo sem nome'}
                </Row>
                <Button
                    className="mt-1 btn-sm"
                    data-tip=""
                    onClick={(event) => {
                    event.stopPropagation()
                    window.open(arquivo.link, '_blank')
                }}>
                    Baixar
                </Button>
                <Button variant="danger" className="mt-1 btn-sm" data-tip="" onClick={(event) => {
                    event.stopPropagation()
                    excluirArquivo(arquivo.id_arquivo_galeria)
                }}>
                    Excluir
                </Button>
            </div>
        );
    }

    function formatarNomeArquivo(nome) {
        let nomeFormatado = nome
        if(nome.length > 61) {
            let extensao = nomeFormatado.split('.').at(-1)
            nomeFormatado = `${nomeFormatado.substring(0, 23)}...${extensao}`
        }
        return nomeFormatado
    }

    return (
        <Form onSubmit={handleSubmit(enviarArquivosSelecionados)}>
            <ReactTooltip effect="solid"/>
            <DommusToggle
                title={`Enviar ${tipo == 'imagem' ? 'Imagens' : 'Anexos'}`}
                toggle={toggleEnviarArquivosAberto}
                className="labels"
                bodyClass="overflow-auto"
                callback={() => setToggleEnviarArquivosAberto(toggleAberto => !toggleAberto)}
            >
                {arquivos.length ? (<>
                    <Row className={" items-galeria " + ((isMobile && " fixed-height-attachment") || "")}>
                        {arquivos.sort((a, b) => {
                            if(a.nome > b.nome){
                                return 1
                            }else if(a.nome < b.nome){
                                return -1
                            }else{
                                return 0;
                            }                            
                        }).map((arquivo, i) => (
                            <div
                                key={i}
                                id={'divFotos-' + i}
                                className={"divImg"}
                                onClick={() => marcarDesmarcarArquivo(arquivo.id_arquivo_galeria)}
                                data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_galeria)) ? 'Desconsiderar' : 'Selecionar'} ${tipo == 'imagem' ? 'Imagem' : 'Anexo'}`}
                            >
                                {tipo == 'imagem' ? <Imagem arquivo={arquivo}/> : <Anexo arquivo={arquivo}/>}
                            </div>
                        ))}
                    </Row>
                    <div className="area_btn_enviar">
                        <DommusTextAreaResize 
                            label="Mensagem" 
                            text={mensagem} 
                            setText={setMensagem} 
                            placeholder="Deixe aqui uma mensagem para acompanhar os anexos ... "
                            required={true}
                        />
                        <Button type="submit" className="btn-enviar-anexos">
                            <span>Enviar</span> <Icon icon={sendOutlined} color="white"/> 
                        </Button>
                    </div>
                </>) : (
                    <Alert variant="warning" className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle}/> {`${tipo == 'imagem'? 'Nenhuma imagem salva' : 'Nenhum anexo salvo'} até o momento.`}
                    </Alert>
                )}
            </DommusToggle>
            <DommusToggle
                title={`Carregar ${tipo == 'imagem' ? 'Imagens' : 'Arquivos'}`}
                toggle={toggleSalvarArquivosAberto}
                className="labels"
                bodyClass="overflow-auto"
                callback={() => setToggleSalvarArquivosAberto(toggleAberto => !toggleAberto)}
            >
                    <Anexos
                        listaAnexos={arquivosSalvar}
                        setListaAnexos={setArquivosSalvar}
                        filesTypes={tipo == 'imagem' ? 'image/jpeg, image/png, image/gif, image/bmp, image/svg+xml, image/webp' : '*'}
                    />
                    <div className="area_btn_anexar">
                        <Button className="anexarImagem" onClick={() => salvarImagens()}>
                            <FontAwesomeIcon icon={faUpload} color="white" /> {"  Upload"}
                        </Button>
                    </div>
            </DommusToggle>
        </Form>
    )
}
