import crm from "./crm";

const tiposTarefaService = {
  index: () => {
    return crm.get("/tipo-tarefa");
  },
  show: (id) => {
    return crm.get("/tipo-tarefa/" + id);
  },
  deletar: (id) => {
    return crm.delete(`/tipo-tarefa/${id}`);
  },
  store: (dados) => {
    let uri = "/tipo-tarefa";
    return crm["post"].call(crm, uri, { tipo_tarefa: dados });
  },
  update: (id, dados) => {
    let uri = "/tipo-tarefa/" + id;
    return crm["put"].call(crm, uri, { tipo_tarefa: dados });
  },
};

export default tiposTarefaService;
