import crm from "./crm";

const filaService = {
  index: () => {
    return crm.get("/fila-chamados");
  },
  show: (idFila) => {
    return crm.get("/fila-chamados/" + idFila);
  },
  store: (fila) => {
    let uri = "/fila-chamados";
    let method = "post";
    return crm[method].call(crm, uri, { fila: fila });
  },
  update: (fila, idFila) => {
    let uri = "/fila-chamados/" + idFila;
    let method = "put";
    return crm[method].call(crm, uri, { fila: fila });
  },
};

export default filaService;
