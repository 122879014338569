import crm from "./crm";

const departamentoService = {
  index: () => {
    return crm.get("/departamento");
  },
  show: (id) => {
    return crm.get(`/departamento/${id}`);
  },
  update: (id, departamento) => {
    return crm.put(`/departamento/${id}`, { departamento: departamento });
  },
  delete: (id) => {
    return crm.delete(`/departamento/${id}`);
  },
  store: (departamento) => {
    return crm.post(`/departamento`, { departamento: departamento });
  },
};

export default departamentoService;
