import React, { createContext, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import relatorioService from "../../services/RelatorioService";

export const RelatorioContext = createContext({});

interface ContextoRelatorioProps {
  children: JSX.Element;
}

export function ContextoRelatorio({ children }: ContextoRelatorioProps) {
  const [listaRelatoriosDiversos, setListaRelatoriosDiversos] = useState([]);

  const recarregarListaRelatoriosDiversos = () => {
    trackPromise(relatorioService.listRelatoriosDiversos())
      .then((response) => {
        setListaRelatoriosDiversos(response.data);
      })
      .catch((err) =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar os relatorios " + err,
          icon: "error",
        })
      );
  };

  return (
    <RelatorioContext.Provider
      value={{
        listaRelatoriosDiversos,
        recarregarListaRelatoriosDiversos,
      }}
    >
      {children}
    </RelatorioContext.Provider>
  );
}
