import { faDownload, faFile, faFileExcel, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Image } from "react-bootstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./index.css";
import urlHelper from "../../helpers/UrlHelper";
import { isMobile } from "react-device-detect";
import AnexoIcone from "../../pages/Widgets/MeusChamados/Chamado/Chat/Anexos/AnexoIcone";

interface DommusThumbnailProps {
    url: string;
}

export function DommusThumbnail({ url }: DommusThumbnailProps) {
    const fileType = useMemo(() => {
        let arrayInfo = url.split('.');
        let extension = "";
        if (arrayInfo.length) {
            extension = arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();
        }
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'svg':
                return "image";
            case 'pdf':
                return "pdf";
            case 'docx':
            case 'doc':
                return 'word';
            case 'xlsx':
            case 'xlsm': 
                return 'excel';
            default:
                return 'file';
        }
    }, [url]);

    const handleClickFile = () => {
        urlHelper.download(url);
    }

    const mountImage = () => {
        return (   
            <div className="thumb img-file">
                <Image src={url} rounded onClick={() => { handleClickFile() }} />
                <div className="layer-download" onClick={() => { handleClickFile() }}>
                    <FontAwesomeIcon icon={faDownload as IconProp} color="white"/>
                </div>
            </div>         
        );
    }


    const mountPdf = () => {
        let arrayInfo = url.split('.');
        let extension = "";
        if (arrayInfo.length) {
            extension = arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();
        }
        return (
            <div className="thumb pdf-file">
                {isMobile ?
                    <div className="pdf-thumb">
                        <AnexoIcone anexo={extension} style={{ fontSize: "3.5rem" }} />
                    </div>
                :
                    <object data={url} type="application/pdf">
                        <p>Arquivo em PDF - <a href={url}>to the PDF!</a></p>
                    </object>
                }
                <div className="layer-download" onClick={() => { handleClickFile() }}>
                    <FontAwesomeIcon icon={faDownload as IconProp} color="white"/>
                </div>
            </div>
        );
    }
    const mountWord = () => {
        return (
            <div className="thumb file-background" style={{ background: "blue" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileWord as IconProp} color="white" />
            </div>
        );
    }
    const mountExcel = () => {
        return (
            <div className="thumb file-background" style={{ background: "green" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileExcel as IconProp} color="white" />
            </div>
        );
    }
    const mountFile = () => {
        return (
            <div className="thumb file-background" style={{ background: "grey" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFile as IconProp} color="white" />
            </div>
        );
    }

    const mountThumbnail = (type: string) => {
        switch (type) {
            case 'image':
                return mountImage();
            case 'pdf':
                return mountPdf();
            case 'word':
                return mountWord();
            case 'excel':
                return mountExcel();
            default:
                return mountFile();
        }
    }

    return (mountThumbnail(fileType));
}