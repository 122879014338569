import React, { useCallback, useContext, useEffect, useState } from "react";
import { TicketType } from "../../../../../../../types/TicketType";
import { QuestionarioPublicoType, QuestionarioType } from "../../../../../../../contexts/QuestionarioContext/types";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../../../services/QuestionarioService";
import Swal from "sweetalert2";
import { Alert } from "react-bootstrap";
import "./index.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ModalDommus from "../../../../../../../components/Modal";
import { GeracaoLinkQuestionarioPublico } from "../../../../../../../components/GeracaoLinkQuestionarioPublico";
import { ChamadoContext } from "../../../../../../../components/ContextoGlobal";
import { VisualizacaoQuestionarioPublicoRespondido } from "../../../../../../../components/VisualizacaoQuestionarioPublicoRespondido";
import DommusLoading from "../../../../../../../components/DommusLoading/DommusLoading";

interface ListaQuestionariosPublicoTicketProps {
    ticket: TicketType;
    questionario: QuestionarioType;
}

export function ListaQuestionariosPublicoTicket({ ticket, questionario }: ListaQuestionariosPublicoTicketProps) {
    const [listaQuestionariosPublicos, setListaQuestionariosPublicos] = useState<QuestionarioPublicoType[]>([] as QuestionarioPublicoType[]);
    const [questionarioPublicoSelecionado, setQuestionarioPublicoSelecionado ] = useState<QuestionarioPublicoType>({} as QuestionarioPublicoType)
    const [openModalGerarLink, setOpenModalGerarLink] = useState(false);
    const [openModalVisualizarQuestionario, setOpenVisualizarQuestionario] = useState(false);
    const {permissoesQuestionarioTicket}:any = useContext(ChamadoContext);
    const [carregandoLista, setCarregandoLista] = useState(true);

    const resolveBadgeStatus = useCallback((questionarioPublico: QuestionarioPublicoType) => {
        const dataExpira = moment(questionarioPublico.criado_em).add(questionarioPublico.sla_expira, 'hours');
        const expirado = moment() > dataExpira;

        if (questionarioPublico?.data_respondido) {
            return <span className="badge-resposta badge-respondido">Respondido em: {moment(questionarioPublico?.data_respondido).format("DD/MM/YYYY HH:mm")}</span>
        } else {
            if(questionarioPublico.link_publico){
                return <span className={`badge-resposta badge-${expirado ? 'expirado' : 'aberto'}`}>{expirado ? "Expirado em: " : "Expira em: "} {dataExpira.format("DD/MM/YYYY HH:mm")}</span>
            }else{
                return <span className={`badge-resposta badge-error`}>Erro ao gerar Link</span>
            }
        }

    }, []);

    useEffect(() => {
        
        QuestionarioService.buscarQuestionariosPublicosTicket(ticket.id_ticket, questionario.id_questionario).then((response: any) => {
            setListaQuestionariosPublicos(response.data);
            setCarregandoLista(false);
        }).catch((error) => {
            setCarregandoLista(false);
            Swal.fire(
                'Ooops... Ocorreu um erro ao carregar questionários públicos.',
                '',
                'error'
            );
        })
    }, [])

    const handleRemoverQuestionarioPublico = (questionarioPublico:QuestionarioPublicoType)=>{
        Swal.fire({
            title: 'Remoção de Formulário de respostas',
            text: `Tem certeza que deseja remover o formulário de respostas`,
            icon: 'question',
            confirmButtonText: 'Sim, Remover.',
            showCancelButton: true,
            cancelButtonText: 'Não, Cancelar.',
            confirmButtonColor: 'red'
        }).then(result => {
            if (result.isConfirmed) {
                trackPromise(QuestionarioService.removerQuestionarioPublico(questionarioPublico.id_questionario_publico)).then((response) => {
                    setListaQuestionariosPublicos((current)=>{
                        return current.filter((item) => item.id_questionario_publico !== questionarioPublico.id_questionario_publico);
                    });
                    Swal.fire({
                        titleText: "Formulário de Respostas",
                        text: "Formulário de Respostas removido com sucesso!",
                        icon: 'success'
                    });
                }).catch((error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text: error?.response?.data?.message ?? `Ocorreu um erro ao remover formulário de respostas.${error}`,
                        icon: 'error'
                    });
                })
            }
        })
    }

    return (
        carregandoLista ?
            <DommusLoading loadWrapperPosition={"relative"} fundo="#eee"/>
        :
        <>
            <div className="lista-questionarios-publico-section">
                {listaQuestionariosPublicos.length ?
                    listaQuestionariosPublicos.map((item, index) => {
                        return (
                            <div
                                className="bloco-questionario"
                                key={index}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setQuestionarioPublicoSelecionado(item);
                                    setOpenVisualizarQuestionario(true);
                                }}
                            >
                                <div className="header-section">
                                    <div>{resolveBadgeStatus(item)}</div>
                                    <div className="btns-section">
                                        <button 
                                            className="btn btn-link"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setQuestionarioPublicoSelecionado(item);
                                                setOpenModalGerarLink(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faLink as IconProp}                                                                                                
                                                style={{ cursor: "pointer" }}                                                
                                            /> <span>Link</span>
                                        </button>
                                        {permissoesQuestionarioTicket?.remover && 
                                            <button 
                                                className="btn btn-remover"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoverQuestionarioPublico(item);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash as IconProp}                                                                                                
                                                    style={{ cursor: "pointer" }}                                              
                                                />
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="info-section">
                                    <div className="bloco-info-section">
                                        <label>Origem</label>
                                        <span>{item.origem}</span>
                                    </div>
                                    <div className="bloco-info-section">
                                        <label>Contato</label>
                                        <span>{item.contato}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <Alert variant="warning">Nenhum questionário gerado!</Alert>
                }
            </div>
            <ModalDommus
                open={openModalVisualizarQuestionario}
                close={()=>{
                    setQuestionarioPublicoSelecionado({} as QuestionarioPublicoType)
                    setOpenVisualizarQuestionario(false)
                }}
                titulo={`Questionário: ${questionarioPublicoSelecionado?.questionario?.descricao}`}
            >
                <VisualizacaoQuestionarioPublicoRespondido questionarioPublico={questionarioPublicoSelecionado} />
            </ModalDommus>
            <ModalDommus
                open={openModalGerarLink}
                close={()=>{
                    setQuestionarioPublicoSelecionado({} as QuestionarioPublicoType)
                    setOpenModalGerarLink(false)
                }}
                titulo="Geração formulário de respostas"
                size="md"
            >
                <GeracaoLinkQuestionarioPublico
                    ticket={ticket}
                    questionario={questionario}
                    questionarioPublico={questionarioPublicoSelecionado}
                    closeModal={() => { 
                        setQuestionarioPublicoSelecionado({} as QuestionarioPublicoType)
                        setOpenModalGerarLink(false) 
                    }}
                />
            </ModalDommus>
        </>
    )
}