import crm from "./crm";

const usuarioService = {
  index: () => {
    return crm.get("/usuarios/clientes");
  },
  dadosUsuario: () => {
    return crm.get("/usuarios/update");
  },
  aprovarAlteracao: (id, dados) => {
    return crm.put("/usuarios/update/" + id, dados);
  },
  buscarProponenteCpf: (id, cpf) => {
    return crm.get("/processo/" + id + "/proponente/" + cpf);
  },  
  buscarFluxoPagamento: (id, conciliar=false, validarDistrato=false) => {
    return crm.get("/processo/pagamento/" + id, { params: { conciliar, validarDistrato } });
  },
};

export default usuarioService;
