import { successToast } from "../components/DommusToast";

const stringHelper = {
	isBlank: (str) => {
		return (!str || /^\s*$/.test(str));
	},
	isEmpty: (str) => {
		return (!str || 0 === str.length);
	},
	isEmail: (email) => {
		var emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
		return emailPattern.test(email);
	},
	copyBoard: (textToCopy)=>{

		let TempText = document.createElement("input");
		TempText.value = textToCopy;
		document.body.appendChild(TempText);
		TempText.select();

		document.execCommand("copy");
		document.body.removeChild(TempText);

		document.execCommand("copy");

		successToast.fire({
			text: "Item copiado para a área de transferência."
		})
	},
	cepMask: (value) => {
		if(!value){
			return '';
		}
		return value
			.replace(/\D/g, '')
			.replace(/(\d{5})(\d)/, '$1-$2')
			.replace(/(-\d{3})\d+?$/, '$1')
	}
};
export default stringHelper;