import React from "react";
import "./index.css";

export default function RecebeAudio({ conteudo, url }) {
    return (
        <>
            <audio className="audio" controls preload="metadata">
                <source src={url} type="audio/ogg" />
            </audio>
        </>
    );
}
