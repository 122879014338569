import crm from "./crm";
import guService from "./GuService";

const PerguntaService = {
    buscar: () => {
        return crm.get('/pergunta');
    },
	buscarTipoResposta: () => {
        return crm.get('/pergunta/tipo-resposta');
    },
    store: (dados) => {
        let uri = "/pergunta";
        return crm['post'].call(crm, uri, dados);
    },
	update: (id, dados) => {
        let uri = `/pergunta/${id}`;
        return crm['put'].call(crm, uri, dados);
    },
    delete: (id) =>{
        let uri = '/pergunta/' + id;
        return crm['delete'].call(crm, uri);
    }    
};

export default PerguntaService;
