import React, { useRef, useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import { Icon } from "@iconify/react";
import sendOutlined from "@iconify-icons/ant-design/send-outlined";

import "./styles.css";
import Anexos from "../Anexos";
import { DommusTextAreaResize } from "../../../../../../components/DommusTextareaResize";

export default function Upload({
    mensagem,
    setMensagemInput,
    enviarImagem,
    setOpenModalUpload,
}) {
    const [listaAnexos, setListaAnexos] = useState([]);
    const [filesTypes] = useState("*");

    return (
        <>
            <Form>
                <Anexos
                    listaAnexos={listaAnexos}
                    setListaAnexos={setListaAnexos}
                    filesTypes={filesTypes}
                />
                <div className="area_btn_anexar">
                    <DommusTextAreaResize 
                        label="Mensagem" 
                        text={mensagem} 
                        setText={setMensagemInput} 
                        placeholder="Deixe aqui uma mensagem para acompanhar os anexos ... "
                        required={true}
                    />
                    <Button
                        className="anexarImagem"
                        onClick={() => {
                            enviarImagem(listaAnexos);
                        }}
                    >
                        Enviar <Icon icon={sendOutlined} color="white" />
                    </Button>
                </div>
            </Form>
        </>
    );
}
