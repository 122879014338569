import React, { createContext, ReactElement, useState, useContext } from "react";
import ConfiguracaoService from "../../services/ConfiguracaoService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { ConfiguracaoType } from "./types";

interface ConfiguracaoContextProps {
    listaConfiguracoes: ConfiguracaoType[];
    recarregarConfiguracaos: () => void;
    openMenuMobile: boolean;
    setOpenMenuMobile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfiguracaoContext = createContext({} as ConfiguracaoContextProps);

export function ConfiguracaoProvider({ children }: { children: ReactElement }) {
    const [listaConfiguracoes, setListaConfiguracoes] = useState<ConfiguracaoType[]>([]);
    const [openMenuMobile, setOpenMenuMobile] = useState(false);


    const recarregarConfiguracaos = () => {
        trackPromise(ConfiguracaoService.index())
            .then((response) => {
                setListaConfiguracoes(response.data);
            })
            .catch((error) => {
                Swal.fire({
                    title: "Ooops...",
                    text: `Houve um erro ao carregar as configurações: ${error?.response?.status}, Erro: ${error?.response?.data?.message}, ${error}`,
                    icon: 'error'
                })
            });
    };

    return (
        <ConfiguracaoContext.Provider
            value={{
                listaConfiguracoes,
                recarregarConfiguracaos,
                openMenuMobile, 
                setOpenMenuMobile
            }}
        >
            {children}
        </ConfiguracaoContext.Provider>
    );
}
