import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./index.css"
interface IconePrioridadeProps {
    prioridade: string;
}

export function IconePrioridade({ prioridade }: IconePrioridadeProps) {
    return (
        <div className="item-icone">
            {prioridade ? (
                prioridade === "Muito Baixa" ? (
                    <div
                        className="icone-prioridade"
                        data-tip={"Prioridade " + prioridade}
                    >
                        <FontAwesomeIcon color="#3D56B2" icon={faChevronDown as IconProp} className="iconDouble" />
                        <FontAwesomeIcon color="#3D56B2" icon={faChevronDown as IconProp} />
                    </div>
                ) : prioridade === "Baixa" ? (
                    <div
                        className="icone-prioridade"
                        data-tip={"Prioridade " + prioridade}
                    >
                        <FontAwesomeIcon color="#3D56B2" icon={faChevronDown as IconProp} />
                    </div>
                ) : prioridade === "Média" ? (
                    <div
                        className="icone-prioridade"
                        data-tip={"Prioridade " + prioridade}
                    >
                        <FontAwesomeIcon color="#FFAB00" icon={faBars as IconProp} />
                    </div>
                ) : prioridade === "Alta" ? (
                    <div
                        className="icone-prioridade"
                        data-tip={"Prioridade " + prioridade}
                    >
                        <FontAwesomeIcon color="#FF5C58" icon={faChevronUp as IconProp} />
                    </div>
                ) : prioridade === "Urgência" ? (
                    <div
                        className="DoubleArrow"
                        data-tip={"Prioridade " + prioridade}
                    >
                        <FontAwesomeIcon color="#FF5C58" icon={faChevronUp as IconProp} className="iconDouble" />
                        <FontAwesomeIcon color="#FF5C58" icon={faChevronUp as IconProp} />
                    </div>
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
        </div>);
}