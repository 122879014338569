import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { cnpjMask, cepMask, realMoeda } from "../../../../../components/InputTextMask";
import moment from "moment";
import urlHelper from "../../../../../helpers/UrlHelper";
import { ChamadoContext } from "../../../../../components/ContextoGlobal";
import { Icon } from '@iconify/react';
import fileEarmarkPdf from '@iconify-icons/bi/file-earmark-pdf';

export default function Contrato() {
  const { processoTicketSelecionado } = useContext(ChamadoContext)
  const aprovacaoContrato = {
    A: "Aprovado",
    R: "Reprovado",
    E: "Em Análise",
    C: "Carregado",
  };

  return (
    <>
      <Card.Body>
        <div className="dados-venda">
          <Row className="row-gap">            
            <Col>
              <div className="item-info">
                <label>Aprovação Contrato:</label>
                <span>{processoTicketSelecionado && aprovacaoContrato[processoTicketSelecionado.processo_aprovacao_contrato] || "---"}</span>
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Data Venda:</label>
                <span>{processoTicketSelecionado && moment(processoTicketSelecionado?.processo_data_venda).format("DD/MM/YYYY") || '---'}</span>
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Data Contabilização:</label>
                <span>{processoTicketSelecionado && moment(processoTicketSelecionado?.venda_data_contabilizacao).format("DD/MM/YYYY") || '---'}</span>
              </div>
            </Col>
          </Row>
          <hr />  
          <Row className="row-gap">            
            <Col>
              <div className="item-info">
                <label>Valor da Venda:</label>
                <span>R$ {processoTicketSelecionado && realMoeda(processoTicketSelecionado.venda_total_contrato) || '---'}</span>
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Recursos Próprios:</label>
                <span>R$ {processoTicketSelecionado && realMoeda(processoTicketSelecionado.venda_total_recursos_proprios) || '---'}</span>
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Recursos Bancários:</label>
                <span>R$ {processoTicketSelecionado && realMoeda(processoTicketSelecionado.analise_credito_total_recursos_bancarios) || '---'}</span>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Tipo de Negociação:</label>
                <span>{processoTicketSelecionado?.tipo_negociacao_descricao || "---"}</span>
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Vendedora:</label>
                <span>{processoTicketSelecionado?.contratada_razao_social || "---"}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="item-info">
                <label>CNPJ:</label>
                <span>{processoTicketSelecionado && cnpjMask(processoTicketSelecionado.contratada_cnpj) || '---'}</span>
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col lg={8}>
              <div className="item-info">
                <label>Endereço:</label>
                <span>{processoTicketSelecionado && 
                  `${processoTicketSelecionado.contratada_endereco}, ${processoTicketSelecionado.contratada_numero} - ${processoTicketSelecionado.contratada_complemento}` || 
                  '---'}
                </span>
              </div>
            </Col>
            <Col lg={4}>
              <div className="item-info">
                <label>CEP:</label>
                <span>{processoTicketSelecionado && cepMask(processoTicketSelecionado?.contratada_cep) || "---"}</span>
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Bairro:</label>
                <span>{processoTicketSelecionado?.contratada_bairro || "---"}</span>
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Cidade:</label>
                <span>{processoTicketSelecionado?.contratada_cidade || "---"}/{processoTicketSelecionado?.contratada_uf || "---"}</span>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="item-info">
                  <label>Anexos:</label>
                  {processoTicketSelecionado?.arquivos ? (
                    processoTicketSelecionado.arquivos.map((arquivo, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="arquivo-item">
                            <Icon icon={fileEarmarkPdf} />
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                urlHelper.download(arquivo.caminho);
                              }}
                            >
                              {arquivo.descricao_documento}
                            </span>                          
                          </div>
                        </React.Fragment>
                      );
                    })
                    ) : (
                      <></>
                      )}
              </div>          
            </Col>
          </Row>
        </div>          
      </Card.Body>
    </>
  );
}
