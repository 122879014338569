import React, { createContext, useEffect, useState } from "react";
import { PerguntaQuestionarioType, QuestionarioType, RespostasQuestionarioType, UltimaAtualizacaoQuestionarioType } from "./types";
import QuestionarioService from "../../services/QuestionarioService";
import Swal from "sweetalert2";
import { PerguntaType } from "../PerguntaContext/types";


interface QuestionarioContextProps {
    listaQuestionarios: QuestionarioType[];
    listaRespostas: RespostasQuestionarioType[];
    ultimasAtualizacoesQuestionario: any;
    recarregarQuestionarios: () => void;
    recarregarRespostas: (idOportunidade: number) => void;
    atualizarRespostas: (novasRespostas: RespostasQuestionarioType[]) => void;
    resolverResposta: (pergunta: PerguntaType, idQuestionario: number) => any;
    adicionarQuestionario: (data: QuestionarioType) => void;
    atualizarQuestionario: (data: QuestionarioType) => void;
    removerQuestionario: (idQuestionario: number) => void;
    atualizarPergunta: (perguntaAtualizada: PerguntaQuestionarioType) => void;
    adicionarPergunta: (idQuestionario: number, perguntas: PerguntaQuestionarioType[]) => void;
    removerPergunta: (perguntaRemover: PerguntaQuestionarioType) => void;
}

export const QuestionarioContext = createContext({} as QuestionarioContextProps);

export function QuestionarioProvider({ children }: { children: JSX.Element }) {
    const [listaQuestionarios, setListaQuestionarios] = useState<QuestionarioType[]>([]);
    const [listaRespostas, setListaRespostas] = useState<RespostasQuestionarioType[]>([]);
    const [ultimasAtualizacoesQuestionario, setUltimasAtualizacoesQuestionario] = useState<any>();



    useEffect(() => {
        let agrupadosQuestionario = listaRespostas.reduce((acc: any, resposta) => {
            const key = resposta.id_questionario;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resposta);
            return acc;
        }, {})

        setUltimasAtualizacoesQuestionario(agrupadosQuestionario);

    }, [listaRespostas]);


    const recarregarQuestionarios = () => {        
        QuestionarioService.buscar()
            .then(response => setListaQuestionarios(response.data.questionarios))
            .catch(error => {                
                Swal.fire({
                    title: "Ooops...",
                    text: 'Houve um erro ao carregar as questionarios',
                    icon: 'error'
                })
            });
    };

    const recarregarRespostas = (idOportunidade: number) => {
        if (listaRespostas.length) {
            return 0
        }
        QuestionarioService.buscarRespostasPorOportunidade(idOportunidade)
            .then((response: any) => setListaRespostas(response.data))
            .catch((error) => {                
                Swal.fire({
                    title: "Ooops...",
                    text: 'Houve um erro ao carregar as respostas',
                    icon: 'error'
                })
            })
    }

    const atualizarRespostas = (novasResposta: RespostasQuestionarioType[]) => {
        setListaRespostas(novasResposta);
    }

    const resolverResposta = (pergunta: PerguntaType, idQuestionario: number) => {
        let resposta = listaRespostas.filter((resposta) => (resposta.id_questionario === idQuestionario && resposta.id_pergunta === pergunta.id_pergunta));
        return resposta[0] || "";
    }

    const adicionarQuestionario = (data: QuestionarioType) => {
        setListaQuestionarios((current) => {
            return [data, ...current];
        });
    };

    const atualizarQuestionario = (data: QuestionarioType) => {
        setListaQuestionarios((current) => {
            return current.map((questionario) => {
                if (questionario.id_questionario === data.id_questionario) {
                    return data;
                }
                return questionario;
            });
        });
    };

    const removerQuestionario = (idQuestionario: number) => {
        setListaQuestionarios((current) => {
            return current.filter(
                (questionario) => questionario.id_questionario !== idQuestionario
            );
        });
    };

    const atualizarPergunta = (perguntaAtualizada: PerguntaQuestionarioType) => {
        setListaQuestionarios((current) => {
            return current.map((questionario) => {
                if (questionario.id_questionario === perguntaAtualizada.id_questionario) {
                    let temp = questionario.perguntas?.map((pergunta) => {
                        if (pergunta.id_pergunta === perguntaAtualizada.id_pergunta) {
                            return perguntaAtualizada;
                        } else {
                            return pergunta;
                        }
                    });
                    questionario.perguntas = temp;
                }
                return questionario;
            }) as QuestionarioType[]
        });
    }


    const adicionarPergunta = (idQuestionario: number, perguntas: PerguntaQuestionarioType[]) => {
        setListaQuestionarios((current) => {
            return current.map((questionario) => {
                if (questionario.id_questionario == idQuestionario) {
                    if (questionario.perguntas?.length) {
                        questionario.perguntas = [...questionario.perguntas, ...perguntas] as PerguntaQuestionarioType[];
                    } else {
                        questionario.perguntas = perguntas;
                    }
                }
                return questionario;
            })
        })
    }

    const removerPergunta = (perguntaRemover: PerguntaQuestionarioType) => {
        setListaQuestionarios((current) => {
            return current.map((questionario) => {
                if (questionario.id_questionario === perguntaRemover.id_questionario) {
                    let temp = questionario.perguntas?.filter((pergunta) => pergunta.id_pergunta !== perguntaRemover.id_pergunta);
                    questionario.perguntas = temp;
                }
                return questionario;
            }) as QuestionarioType[]
        })
    }


    return (
        <QuestionarioContext.Provider
            value={{
                listaQuestionarios,
                listaRespostas,
                ultimasAtualizacoesQuestionario,
                recarregarQuestionarios,
                recarregarRespostas,
                atualizarRespostas,
                resolverResposta,
                adicionarQuestionario,
                atualizarQuestionario,
                removerQuestionario,
                adicionarPergunta,
                atualizarPergunta,
                removerPergunta,
            }}
        >
            {children}
        </QuestionarioContext.Provider>
    );
}
