import { faWhatsapp, faFacebookMessenger, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBullhorn, faComment, faEnvelope, faHandPaper, faHome, faPhoneAlt, faSms, faVideo } from "@fortawesome/free-solid-svg-icons";

const formatDommus = {
  formatDate: (date) => {
    var date = new Date(date);
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " anos atrás";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " mes(es) atrás";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " dia(s) atrás";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hora(s) atrás";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuto(s) atrás";
    }
    return Math.floor(seconds) + " segundo(s) atrás";
  },

  contarAtraso: (date) => {
    var date = new Date(date);
    var dias = 0;
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) > 3) {
        return Math.floor(interval) - 3;
      }
    }
  },

  zeroPad: (num, places) => {
    return String(num).padStart(places, "0");
  },

  formataMysql: (data) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2];

      return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
    } else {
      return null;
    }
  },

  formataMysqlDateHour: (data) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2].split(" ")[0];
      var hora = data.split("/")[2].split(" ")[1];
      return (
        ano +
        "-" +
        ("0" + mes).slice(-2) +
        "-" +
        ("0" + dia).slice(-2) +
        " " +
        hora +
        ":" +
        "00"
      );
    } else {
      return null;
    }
  },

  calcularIdade: (data) => {
    var now = new Date();
    var today = new Date(now.getYear(), now.getMonth(), now.getDate());
    var yearAge = 0;
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
    var dob = new Date(
      data.substring(6, 10),
      data.substring(3, 5) - 1,
      data.substring(0, 2)
    );

    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };
    return age.years;
  },
  isJson: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  formataTextoParaTitulo: (texto) => {
    if(['object', 'undefined'].includes(typeof texto) || !(texto.toString())) {
      return ''
    }
    return texto.toString().split(' ').map(txt => {
      let textoFormatado = txt.toLowerCase()

      return `${textoFormatado.charAt(0).toUpperCase()}${textoFormatado.slice(1)}`
    }).join(' ')
  },

  iconesMeiosComunicacao: {
    whatsapp: {
      icone: faWhatsapp,
      descricao: 'whatsapp',
      cor: '#fff',
      background: '#00a859'
    },
    sms: {
      icone: faSms,
      descricao: 'sms',
      cor: '#fff',
      background: '#353252'
    },
    voip: {
      icone: faPhoneAlt,
      descricao: 'voip',
      cor: '#ffff',
      background: '#f7941d'
    },
    email: {
      icone: faEnvelope,
      descricao: 'email',
      cor: '#fff',
      background: '#F44336'
    },
    video: {
      descricao: 'video',
      icone: faVideo,
      cor: '#fff',
      background: '#4d4d4d'
    },
    chatbot: {
      descricao: 'chatbot',
      icone: faComment,
      cor: '#fff',
      background: '#296ac4',
    },
    'reclame-aqui': {
      descricao: 'Reclame Aqui',
      icone: faBullhorn,
      cor: '#fff',
      background: '#90B823'
    },
    'facebook-messenger': {
      descricao: 'Facebook Messenger',
      icone: faFacebookMessenger,
      cor: '#fff',
      background: '#0099F2'
    },
    instagram: {
      descricao: 'Instagram',
      icone: faInstagram,
      cor: '#fff',
      background: '#D92E76'
    },
    cadastro_manual: {
      icone: faHandPaper,
      descricao: 'Cadastro Manual',
      cor: '#fff',
      background: '#ccc'
    },
    portal_cliente: {
      icone: faHome,
      descricao: 'Portal do Cliente',
      cor: '#fff',
      background: '#AF7615'
    }
  },
  configsMultiselect: {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
  },
  formataValoresMultiSelect(opcoesSelecionadas) {
    return Array.isArray(opcoesSelecionadas) && opcoesSelecionadas.length ? opcoesSelecionadas.map(opcao => opcao.value) : []
  }
};
export default formatDommus;
