import crm from './crm';
import { trackPromise } from 'react-promise-tracker';

interface InternalNoteTicketServiceProps {
    mensagem: String
}

const InternalNoteTicketService = {

	store:(id_ticket: number, dados: InternalNoteTicketServiceProps) => {
		return trackPromise(crm.post(`/ticket/${id_ticket}/nota`, {nota: dados}));
	}
};

export default InternalNoteTicketService;