import React, { useContext, useMemo } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ticketService from "../../../../../services/TicketService";
import { trackPromise } from "react-promise-tracker";
import AnexoIcone from "./Anexos/AnexoIcone";
import guService from "../../../../../services/GuService";
import { ConfiguracaoContext } from "../../../../../contexts/ConfiguracaoContext";

export default function NotasInternas({ mensagens, atualizarNota }) {
    const {listaConfiguracoes} = useContext(ConfiguracaoContext);

    const habilitarRemoverNotaInterna = useMemo(()=>{
        let permissaoRemover = listaConfiguracoes.find((item) => item.chave === "tipos_usuario_permitido_excluir_nota_interna");
        if(permissaoRemover && permissaoRemover?.valor?.length && permissaoRemover.valor.includes(Number(guService.getLocalState("tipoUsuario")))){
            return true;
        }
        return false;
    },[])

    function openImage(url) {
        window.open(url, "_blank");
    }

    function handleRemoveNota(nota) {
        Swal.fire({
            title: "Excluir Nota?",
            text: "Tem certeza que deseja excluir essa nota?",
            showCancelButton: true,
            cancelButtonText: "Não, cancelar",
            confirmButtonText: "Sim, excluir",
            icon: "warning",
        }).then((resposta) => {
            if (resposta.isConfirmed) {
                trackPromise(ticketService.removeNotaInternaById(nota.id_ticket_nota_interna))
                    .then((response) => {      
                        atualizarNota(response.data);
                        Swal.fire(
                            "Nota Excluída",
                            "Nota excluída com sucesso.",
                            "success"
                        );
                    })
                    .catch((error) =>
                        Swal.fire(
                            "Erro!",
                            `Houve um erro ao tentar excluir a nota. ${error}`,
                            "error"
                        )
                    );
            }
        });
    }

    function itemAnexo(arquivo){
        return(
            <div className="anexo-section" onClick={()=>{window.open(arquivo.url, '_blank')}}>
                <AnexoIcone anexo={arquivo?.nome?.split('.').pop()} style={{ fontSize: "1rem" }} />
                <span>{arquivo?.nome}</span>
            </div>
        )
    }
    

    function renderMensagem(mensagem, index) {
        const date = moment(mensagem.criado_em).format("DD/MM/YYYY, HH:mm");
        const dateExclusao = moment(mensagem?.data_exclusao).format("DD/MM/YYYY, HH:mm");
        const { nome, text, url, ativo, nome_usuario_exclusao, tipo_upload, arquivos } = mensagem;

        return (
            <li className="Messages-message" key={index}>
                <div className="Message-content">                    
                    <div className="username">{date + " " + nome}</div>
                    <div className="text">
                        {ativo === "1" && habilitarRemoverNotaInterna && (
                            <button
                                className="btn-excluir-nota"
                                onClick={() => {
                                    handleRemoveNota(mensagem);
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        )}
                        {ativo === "1" ?
                            (url ? (
                                tipo_upload == "I" ? (
                                    <img
                                        onClick={() => openImage(url)}
                                        style={{
                                            maxWidth: "100%",
                                            cursor: "pointer",
                                        }}
                                        src={url}
                                        alt= {<div dangerouslySetInnerHTML={{ __html: text}}></div>}
                                    />
                                ) : (
                                    <div
                                        onClick={() => openImage(url)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperclip}
                                            color="white"
                                        />{" "}
                                         {<div dangerouslySetInnerHTML={{ __html: text}}></div>}
                                    </div>
                                )
                            ) : (
                                <>
                                    <span> {<div dangerouslySetInnerHTML={{ __html: text}}></div>}</span>
                                    {arquivos && arquivos.length ? 
                                        <>
                                            <hr/>
                                            {arquivos.map((arquivo, index)=>{
                                                return (
                                                <div key={index}>
                                                    {itemAnexo(arquivo)}
                                                </div>
                                                )
                                            })}
                                        </>
                                        : <></>
                                    }
                                </>
                            ))                            
                            :
                            <span>Nota excluída por {nome_usuario_exclusao} em {dateExclusao}.</span>
                        }
                    </div>
                </div>
            </li>
        );
    }

    return (
        <ul className="Messages-list">
            {mensagens.map((m, index) => renderMensagem(m, index))}
        </ul>
    );
}
