import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.css";
import AnexoIcone from "../../../../../Chat/Anexos/AnexoIcone";
import urlHelper from "../../../../../../../../../helpers/UrlHelper";

export default function FileDownload({ conteudo, url }) {
    var nome = conteudo || url;
    var index = url && url.indexOf(".");
    var extensao = url && index && url.substring(index + 1);

    return (
        <>
            {
                <>
                    <a
                        className="linkFile"
                        href="#"
                        onClick={() => {
                            url && urlHelper.download(url);
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Row>
                            <Col sm={2}>
                                <AnexoIcone
                                    anexo={extensao}
                                    style={{ fontSize: "3em" }}
                                />
                            </Col>
                            <Col
                                style={{
                                    marginTop: "0.75rem",
                                    marginLeft: "0.5rem",
                                }}
                            >
                                {nome}
                            </Col>
                        </Row>
                    </a>
                </>
            }
        </>
    );
}
