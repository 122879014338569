import React, { Fragment, useMemo } from "react";
import "./index.css";
import {
    Alert,
    Col,
    Nav,
    OverlayTrigger,
    Tab,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import arrowUpO from "@iconify-icons/gg/arrow-up-o";
import moment from "moment";
import ticketService from "../../../../../../../../services/TicketService";
import DommusBase64AudioPlayer from "../../../../../../../../components/DommusBase64AudioPlayer";

export function VoipComponent({ atendimento, conteudo }) {
    const anotacoes = useMemo(() => {
        return  JSON.parse(atendimento.detalhes)?.anotacoes ?? [];
    }, [atendimento]);

    const criarPromiseGravacao = (callSid) => {
        return ticketService.buscaGravacaoVoip.bind(this, callSid);
    };

    return (
        <div id="anotacoes">
            <Tab.Container id="left-tabs" defaultActiveKey="anotacoes">
                <div className="conteudo">
                    <div className="header">
                        <Nav
                            variant="pills"
                            className="menu_anotacoes overflow-auto"
                        >
                            <Col>
                                <Nav.Item>
                                    <Nav.Link eventKey="anotacoes">
                                        Anotações
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col>
                                <Nav.Item>
                                    <Nav.Link eventKey="historicoAtendimento">
                                        Histórico
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="anotacoes">
                            <div className="anotacoes-container">
                                {anotacoes.length ? (
                                    anotacoes.map((anotacao, index) => {
                                        return (
                                            <span
                                                key={index}
                                                className="anotacao-item"
                                            >
                                                {anotacao.anotacao ?? "---"}
                                            </span>
                                        );
                                    })
                                ) : (
                                    <Alert variant="warning">
                                        Não possui nenhuma anotação
                                    </Alert>
                                )}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="historicoAtendimento">
                            <br />
                            <div className="atendimentosdoDiaContent">
                                <Table
                                    className="tebelaAtendimentosdoDiaContent"
                                    responsive="sm"
                                    striped
                                    bordered
                                    hover
                                    size="sm"
                                >
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>ID</th>
                                            <th>Dia/Hora</th>
                                            <th>Duração</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {conteudo ? (
                                            conteudo.map((chamados, index) => {
                                                const detalhesChamado =
                                                    JSON.parse(
                                                        chamados?.detalhes
                                                    );
                                                const promiseBuscaAudio =
                                                    (detalhesChamado?.CallSid &&
                                                        criarPromiseGravacao(
                                                            detalhesChamado.CallSid
                                                        )) ||
                                                    null;
                                                return (
                                                    <Fragment key={chamados.id}>
                                                        <tr>
                                                            <td>
                                                                {chamados.direcao ===
                                                                "recebido" ? (
                                                                    <OverlayTrigger
                                                                        key="buttonRecebido"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip
                                                                                id={`tooltip-recebida`}
                                                                            >
                                                                                Recebida
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon={
                                                                                arrowUpO
                                                                            }
                                                                            width="2rem"
                                                                            color="#4CAF50"
                                                                            rotate="180deg"
                                                                        />
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        key="buttonRecebido"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip
                                                                                id={`tooltip-realizada`}
                                                                            >
                                                                                Realizada
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon={
                                                                                arrowUpO
                                                                            }
                                                                            width="2rem"
                                                                            color="#265d9c"
                                                                        />
                                                                    </OverlayTrigger>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {chamados.id}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    chamados.criado_em
                                                                ).format(
                                                                    "DD/MM/YYYY HH:mm"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {moment
                                                                    .utc(
                                                                        detalhesChamado.duracao *
                                                                            1000
                                                                    )
                                                                    .format(
                                                                        "HH:mm:ss"
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                                <td colSpan="4" style={{textAlign: "center"}}>
                                                                    {promiseBuscaAudio && (
                                                                        <DommusBase64AudioPlayer
                                                                            promiseBuscaAudio={
                                                                                promiseBuscaAudio
                                                                            }
                                                                            indice={
                                                                                chamados.id
                                                                            }
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                    </Fragment>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}
