const convertFilesToBase64 = {
    convertFileToBase64: async (acceptedFile) => {
        const filePathsPromises = [];
        let paraBase64Files;
        for (let i = 0; i < acceptedFile.length; i++) {
            paraBase64Files = paraBase64(acceptedFile[i]);
            filePathsPromises.push(paraBase64Files)
        }
        const pegaTodosArquivos = await Promise.all(filePathsPromises);
        const dadosArquivo = pegaTodosArquivos.map((base64File) => ({ conteudo: base64File.base64, nome: base64File.nome, tipo: base64File.tipo }));
        return dadosArquivo;
    }
}
const paraBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ base64: reader.result.split(/base64,/)[1], nome: file.name, tipo: file.type });
        reader.onerror = error => reject(error);
    });
};

export default convertFilesToBase64;