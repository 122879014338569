import React, { useMemo, useState } from "react";
import Pagination from "react-bootstrap/cjs/Pagination";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "./index.css";

function DommusTabelaPaginada(props) {
  const [tamanhoPagina, setTamanhoPagina] = useState(props.tamanhoPagina || 10);
  const [pagina, setPagina] = useState(props.paginaInicial || 1);
  const inicioArray = (pagina - 1) * tamanhoPagina;
  const tamanhoArray =
    inicioArray + tamanhoPagina < props.linhasCorpo.length
      ? inicioArray + tamanhoPagina
      : props.linhasCorpo.length;
  const paginaFinal = Math.ceil(props.linhasCorpo.length / tamanhoPagina);
  const arrayLinhas = props.linhasCorpo.slice(inicioArray, tamanhoArray);
  const itensPaginacao = [];

  const page_config = {
    limite: 15,
    min_paginas: 3,
  };
  let ultimaPaginaFoiOculta = false;
  for (let numeroPagina = 1; numeroPagina <= paginaFinal; numeroPagina++) {
    const paginaItem = (
      <Pagination.Item
        key={numeroPagina}
        active={numeroPagina === pagina}
        onClick={() => {
          setPagina(numeroPagina);
        }}
      >
        {numeroPagina}
      </Pagination.Item>
    );

    let numeroPaginaOculta;

    if (
      paginaFinal <= page_config.limite ||
      (pagina - 2 <= numeroPagina && pagina + 2 >= numeroPagina) ||
      pagina === numeroPagina ||
      numeroPagina <= page_config.min_paginas ||
      numeroPagina > paginaFinal - page_config.min_paginas
    ) {
      itensPaginacao.push(paginaItem);
      ultimaPaginaFoiOculta = false;
    } else {
      if (!ultimaPaginaFoiOculta) {
        if (pagina < numeroPagina) {
          numeroPaginaOculta = pagina + page_config.min_paginas + 2;
        } else {
          numeroPaginaOculta = pagina - page_config.min_paginas - 2;
        }

        if (numeroPaginaOculta > paginaFinal || numeroPaginaOculta < 1) {
          numeroPaginaOculta = pagina;
        }

        let paginaOculta = (
          <Pagination.Item
            key={numeroPagina}
            active={false}
            onClick={() => {
              setPagina(numeroPaginaOculta);
            }}
          >
            ...
          </Pagination.Item>
        );
        itensPaginacao.push(paginaOculta);
        ultimaPaginaFoiOculta = true;
      }
    }
  }

  const footerCount = useMemo(()=>{
    if((props?.habilitarCountFooter || false) && arrayLinhas?.length){
      let valores = [];
      props.linhasCabecalho.forEach((cabecalho, indexC) =>{
        arrayLinhas.forEach((linha, indexL) => {              
            valores[indexC] = Number(linha?.props?.children[indexC].props?.children) + Number(valores[indexC] ?? "0");            
        });
      })

      return (
        <tr>
          {valores.map((valor, index)=>{
            if(props?.ignorarPrimeiraColunaFooter && index === 0){
              return <th key={index}>TOTAL</th>;
            }else{
              return <th key={index}>{valor}</th>;
            }
          })}
        </tr>
      )
    }
    return [];
  },[arrayLinhas])

  const opcoesTabela = Object.assign(
    {
      responsive: true,
      bordered: true,
      hover: true,
    },
    props.optionsTabela
  );
  return (
    <>
      <div className={"card card-table " + props.customClasses}>
        <Table className={"tabela-dommus"} {...opcoesTabela}>
          <thead
            className={"thead-dark"}
            style={{ backgroundColor: props.cor }}
          >
            {props.linhasCabecalho}
          </thead>
          <tbody>{arrayLinhas}</tbody>
          <tfoot className={"tfoot-dark"}>{props.linhasRodape ?? footerCount}</tfoot>
        </Table>
      </div>
      <div
        className="d-sm-flex justify-content-between"
        style={{ marginTop: 10 }}
      >
        <Form.Group controlId="paginationPageSize">
          <Form.Control
            type="number"
            step={props.stepTamanho || 5}
            min={props.tamanhoMinimo || 5}
            value={tamanhoPagina}
            size="sm"
            style={{ width: "40%" }}
            onChange={(e) => {
              setTamanhoPagina(e.target.value);
            }}
          />
          <Form.Text className="text-muted">Itens por página.</Form.Text>
        </Form.Group>
        <Pagination>
          <Pagination.First
            onClick={() => {
              setPagina(1);
            }}
          />
          <Pagination.Prev
            onClick={() => {
              if (pagina !== 1) {
                setPagina(pagina - 1);
              }
            }}
          />
          {itensPaginacao}
          <Pagination.Next
            onClick={() => {
              if (pagina !== paginaFinal) {
                setPagina(pagina + 1);
              }
            }}
          />
          <Pagination.Last
            onClick={() => {
              setPagina(paginaFinal);
            }}
          />
        </Pagination>
      </div>
    </>
  );
}

export default DommusTabelaPaginada;
