import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import empreendimentoService from "../../services/EmpreendimentoService";
import { errorToast } from "../DommusToast";
import { DadosEmpreendimentoCondominioType } from "../../types/DadosEmpreendimentoCondominio";
import stringHelper from "../../helpers/StringHelper";

interface DadosEmpreendimentoCondominioProps {
    idEmpreendimento: string
    idCondominio: number
}

export function DadosEmpreendimentoCondominio({ idEmpreendimento, idCondominio } : DadosEmpreendimentoCondominioProps) {
    const [dadosEmpreendimento, setDadosEmpreendimento] = useState({} as DadosEmpreendimentoCondominioType)

    useEffect(() => {
        empreendimentoService.buscarDadosEmpreendimento(idEmpreendimento, { id_condominio: idCondominio } as any)
            .then(response =>  setDadosEmpreendimento(response.data))
            .catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao buscar os dados do empreendimento.' })
            })
    }, [idEmpreendimento])

    return (
        <>
          <Card.Body>
            <div className="dadosPessoaisCliente">
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <Col xs={3} sm={3} lg={3}>
                  <div className="foto">
                    <div className="placeholder">
                      <div className=" fotoEnvolvido">
                        <FontAwesomeIcon icon="building" color="#444444" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={9} sm={9} lg={9}>
                  <div className="nomeCliente">
                    <Card.Text className="mb-0">
                      <b className="text-muted">Empreendimento:</b>
                    </Card.Text>
                    <Card.Text>
                      <b>{dadosEmpreendimento?.nome}</b>
                      <span data-tip="Copiar">
                          <FontAwesomeIcon 
                            className="copy-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if(dadosEmpreendimento?.nome) {
                                stringHelper.copyBoard(dadosEmpreendimento?.nome)
                              }
                            }} 
                            icon="clone"
                         />
                      </span>
                    </Card.Text>
                  </div>
                 </Col>
              </Row>
              <hr/>
              <div className="dados-proponente">
                <Row className="row-gap">            
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Endereço:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.endereco || '-'}</span>      
                        {dadosEmpreendimento?.endereco && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.endereco) {
                                  stringHelper.copyBoard(dadosEmpreendimento.endereco)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>CEP:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cep && stringHelper.cepMask(dadosEmpreendimento.cep) || '-'}</span>      
                        {dadosEmpreendimento?.cep && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cep) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cep)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                      <div className="item-info">
                        <label>Número:</label>
                        <div className="item-copy">
                          <span>{dadosEmpreendimento?.numero || '-'}</span>      
                          {dadosEmpreendimento?.numero && 
                            <div data-tip="Copiar">
                              <FontAwesomeIcon 
                                className="copy-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if(dadosEmpreendimento?.numero) {
                                    stringHelper.copyBoard(dadosEmpreendimento.numero)
                                  }
                                }} icon="clone" />
                            </div>
                          }  
                          </div>
                      </div>
                  </Col>         
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Bairro:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.bairro || '-'}</span>      
                        {dadosEmpreendimento?.bairro && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.bairro) {
                                  stringHelper.copyBoard(dadosEmpreendimento.bairro)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Cidade:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cidade || '-'}</span>      
                        {dadosEmpreendimento?.cidade && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cidade) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cidade)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>UF:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.uf || '-'}</span>      
                        {dadosEmpreendimento?.uf && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.uf) {
                                  stringHelper.copyBoard(dadosEmpreendimento.uf)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <Col xs={3} sm={3} lg={3} className="mt-3">
                  <div className="foto">
                    <div className="placeholder">
                      <div className="fotoEnvolvido" style={{ fontSize: '50px' }}>
                        <FontAwesomeIcon icon="warehouse" color="#444444" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={9} sm={9} lg={9}>
                  <div className="nomeCliente">
                    <Card.Text className="mb-0">
                      <b className="text-muted">Condomínio</b>
                    </Card.Text>
                  </div>
                </Col>
              </Row>
              <hr/>
              <div className="dados-proponente">
                <Row className="row-gap">            
                  <Col sm={12} className="px-1">
                    <div className="item-info">
                      <label>Razão Social:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.razao_social_condominio || '-'}</span>      
                        {dadosEmpreendimento?.razao_social_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.razao_social_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.razao_social_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <div className="dados-proponente">
                <Row className="row-gap">            
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>CNPJ:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cnpj_condominio || '-'}</span>      
                        {dadosEmpreendimento?.cnpj_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cnpj_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cnpj_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>CEP:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cep_condominio && stringHelper.cepMask(dadosEmpreendimento.cep_condominio) || '-'}</span>      
                        {dadosEmpreendimento?.cep_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cep_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cep_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">            
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>E-mail:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.email_condominio || '-'}</span>      
                        {dadosEmpreendimento?.email_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.email_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.email_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Telefone:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.telefone_condominio || '-'}</span>      
                        {dadosEmpreendimento?.telefone_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.telefone_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.telefone_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Nome para Contato do Condomínio:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.nome_contato_condominio || '-'}</span>      
                        {dadosEmpreendimento?.nome_contato_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.nome_contato_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.nome_contato_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                      <div className="item-info">
                        <label>Logradouro:</label>
                        <div className="item-copy">
                          <span>{dadosEmpreendimento?.logradouro_condominio || '-'}</span>      
                          {dadosEmpreendimento?.logradouro_condominio && 
                            <div data-tip="Copiar">
                              <FontAwesomeIcon 
                                className="copy-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if(dadosEmpreendimento?.logradouro_condominio) {
                                    stringHelper.copyBoard(dadosEmpreendimento.logradouro_condominio)
                                  }
                                }} icon="clone" />
                            </div>
                          }  
                          </div>
                      </div>
                  </Col>         
                </Row>
                <Row className="row-gap">  
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Endereço:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.endereco_condominio || '-'}</span>      
                        {dadosEmpreendimento?.endereco_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.endereco_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.endereco_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col> 
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Número:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.numero_condominio || '-'}</span>      
                        {dadosEmpreendimento?.numero_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.numero_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.numero_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Complemento:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.complemento_condominio || '-'}</span>      
                        {dadosEmpreendimento?.complemento_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.complemento_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.complemento_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Bairro:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.bairro_condominio || '-'}</span>      
                        {dadosEmpreendimento?.bairro_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.bairro_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.bairro_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Cidade:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cidade_condominio || '-'}</span>      
                        {dadosEmpreendimento?.cidade_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cidade_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cidade_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>UF:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.uf_condominio || '-'}</span>      
                        {dadosEmpreendimento?.uf_condominio && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.uf_condominio) {
                                  stringHelper.copyBoard(dadosEmpreendimento.uf_condominio)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <Col xs={3} sm={3} lg={3}>
                  <div className="foto">
                    <div className="placeholder">
                      <div className=" fotoEnvolvido">
                        <FontAwesomeIcon icon="user" color="#444444" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={9} sm={9} lg={9}>
                  <div className="nomeCliente">
                    <Card.Text className="mb-0">
                      <b className="text-muted">Síndico:</b>
                    </Card.Text>
                    <Card.Text>
                      <b>{dadosEmpreendimento?.nome_sindico}</b>
                      <span data-tip="Copiar">
                          <FontAwesomeIcon 
                            className="copy-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if(dadosEmpreendimento?.nome_sindico) {
                                stringHelper.copyBoard(dadosEmpreendimento?.nome_sindico)
                              }
                            }} 
                            icon="clone"
                         />
                      </span>
                    </Card.Text>
                  </div>
                 </Col>
              </Row>
              <hr/>
              <div className="dados-proponente">
                <Row className="row-gap">            
                  <Col sm={12}>
                    <div className="item-info">
                      <label>E-mail:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.email_sindico || '-'}</span>      
                        {dadosEmpreendimento?.email_sindico && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.email_sindico) {
                                  stringHelper.copyBoard(dadosEmpreendimento.email_sindico)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
                <Row className="row-gap">  
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>Telefone Fixo:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.telefone_fixo_sindico || '-'}</span>      
                        {dadosEmpreendimento?.telefone_fixo_sindico && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.telefone_fixo_sindico) {
                                  stringHelper.copyBoard(dadosEmpreendimento.telefone_fixo_sindico)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col> 
                  <Col xs={12} sm={12} lg={12} xl={6}>
                      <div className="item-info">
                        <label>Celular:</label>
                        <div className="item-copy">
                          <span>{dadosEmpreendimento?.celular_sindico || '-'}</span>      
                          {dadosEmpreendimento?.celular_sindico && 
                            <div data-tip="Copiar">
                              <FontAwesomeIcon 
                                className="copy-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if(dadosEmpreendimento?.celular_sindico) {
                                    stringHelper.copyBoard(dadosEmpreendimento.celular_sindico)
                                  }
                                }} icon="clone" />
                            </div>
                          }  
                          </div>
                      </div>
                  </Col>         
                </Row>
                <Row className="row-gap">   
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>CPF:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.cpf_sindico || '-'}</span>      
                        {dadosEmpreendimento?.cpf_sindico && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.cpf_sindico) {
                                  stringHelper.copyBoard(dadosEmpreendimento.cpf_sindico)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12} xl={6}>
                    <div className="item-info">
                      <label>RG:</label>
                      <div className="item-copy">
                        <span>{dadosEmpreendimento?.rg_sindico || '-'}</span>      
                        {dadosEmpreendimento?.rg_sindico && 
                          <div data-tip="Copiar">
                            <FontAwesomeIcon 
                              className="copy-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(dadosEmpreendimento?.rg_sindico) {
                                  stringHelper.copyBoard(dadosEmpreendimento.rg_sindico)
                                }
                              }} icon="clone" />
                          </div>
                        }  
                        </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Card.Body>
      </>
    );
}