import crm from './crm';

const TicketTags = {
	index:()=>{
		return crm.get('/ticket-tags');
	},
	show:(id)=>{
		return crm.get('/ticket-tags/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket-tags/' + id;
        return crm['put'].call(crm, uri, {tag: dados});
    },
	store: (dados) => {
        let uri = '/ticket-tags';
        return crm['post'].call(crm, uri, {tag: dados});
    },
};

export default TicketTags;
