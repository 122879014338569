import React, { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";
import { ListaServicoCategoriaType } from "../../types/ListaServicoCategoriaType";
import { DommusMultiButtonType } from "../DommusMultiButton";
import { OrdemServicoStatusType } from "../../types/OrdemServicoStatusType";

interface AssistenciaTecnicaContextProps {
  listaServicoCategorias: ListaServicoCategoriaType[]
  setListaServicoCategorias: Dispatch<SetStateAction<ListaServicoCategoriaType[]>>
  opcoesAnaliseServicoGarantia: DommusMultiButtonType[]
  statusOrdemServico: OrdemServicoStatusType[]
  setStatusOrdemServico: Dispatch<SetStateAction<OrdemServicoStatusType[]>>
}

export const AssistenciaTecnicaContext = createContext({} as AssistenciaTecnicaContextProps);

export function AssistenciaTecnicaContextProvider({ children }: { children: ReactElement }) {
  const [listaServicoCategorias, setListaServicoCategorias] = useState<ListaServicoCategoriaType[]>([]);
  const [statusOrdemServico, setStatusOrdemServico] = useState<OrdemServicoStatusType[]>([]);
  const opcoesAnaliseServicoGarantia = [
      {
        text: "Sem análise",
        color: "#FF8E00", 
        value: "N"
      },
      {
        text: "Aprovado",
        color: "#85C88A", 
        value: "A"
      },
      {
        text: "Reprovado",
        color: "#FF6464", 
        value: "R"
      },
  ]

  return (
    <AssistenciaTecnicaContext.Provider 
      value={{ 
        listaServicoCategorias, setListaServicoCategorias,
        statusOrdemServico, setStatusOrdemServico,
        opcoesAnaliseServicoGarantia }}
      >
      {children}
    </AssistenciaTecnicaContext.Provider>
  );
}
