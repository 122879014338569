import crm from './crm';

const TicketCategoria = {
	index:()=>{
		return crm.get('/ticket-categoria');
	},
	show:(id)=>{
		return crm.get('/ticket-categoria/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket-categoria/' + id;
        return crm['put'].call(crm, uri, {categoria: dados});
    },
	store: (dados) => {
        let uri = '/ticket-categoria';
        return crm['post'].call(crm, uri, {categoria: dados});
    },
};

export default TicketCategoria;
