import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { cepMask, realMoeda } from "../../../../../components/InputTextMask";
import { ChamadoContext } from "../../../../../components/ContextoGlobal";

export default function Produto() {
  const { processoTicketSelecionado } = useContext(ChamadoContext)

  return (
    <>
      <Card.Body>
        <div className="dados-produto">
          <Row>            
            <Col>
              <div className="item-info">
                <label>Empreendimento:</label>
                <span>{processoTicketSelecionado?.empreendimento_nome ?? "---"}</span>
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Unidade:</label>
                <span>{processoTicketSelecionado?.unidade_descricao ?? "---"}</span>        
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Tipologia:</label>
                <span>{processoTicketSelecionado?.tipo_unidade_descricao ?? "---"}</span>        
              </div>
            </Col>
          </Row>                
          <hr />
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Área Privativa Total:</label>
                <span>{processoTicketSelecionado?.unidade_area_privativa_total ? `${processoTicketSelecionado?.unidade_area_privativa_tota} m²` : "---"}</span>        
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Área Exclusiva Total:</label>
                <span>{processoTicketSelecionado?.unidade_area_privativa_total ? `${processoTicketSelecionado.unidade_area_privativa_total} m²` : "---"}</span>        
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Vaga de Garagem:</label>
                <span>{processoTicketSelecionado?.unidade_vaga_garagem ?? "---"}</span>        
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Previsão de Entrega:</label>
                <span>{processoTicketSelecionado?.empreendimento_banco_previsao_entrega ?? "---"}</span>        
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Valor de Venda:</label>
                <span>R$ {processoTicketSelecionado?.unidade_valor ? realMoeda(processoTicketSelecionado?.unidade_valor) : "---"}</span>        
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Valor de Avaliação:</label>
                <span>R$ {processoTicketSelecionado?.unidade_valor_avaliacao_banco ? realMoeda(processoTicketSelecionado?.unidade_valor_avaliacao_banco) : "---"}</span>       
              </div>
            </Col>
          </Row>       
          <hr />
          <Row className="row-gap">
            <Col lg={8}>
              <div className="item-info">
                <label>Endereço do Empreendimento:</label>
                <span>{processoTicketSelecionado?.empreendimento_endereco ?? "---"}</span>       
              </div>
            </Col>
            <Col lg={4}>
              <div className="item-info">
                <label>CEP:</label>
                <span>{processoTicketSelecionado?.empreendimento_cep ?? "---"}</span>       
              </div>
            </Col>
          </Row>
          <Row className="row-gap">
            <Col>
              <div className="item-info">
                <label>Bairro:</label>
                <span>{processoTicketSelecionado?.empreendimento_bairro ?? "---"}</span>       
              </div>
            </Col>
            <Col>
              <div className="item-info">
                <label>Cidade/UF:</label>
                <span>{processoTicketSelecionado?.empreendimento_cidade ?? "---"}</span>       
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </>
  );
}
