import api from "./api";
const setLocalState = (name, value) => {
  localStorage.setItem("@DommusCRM-" + name, value);
};
const getLocalState = (name) => {
  const value = localStorage.getItem("@DommusCRM-" + name);
  // if (!value) console.log(name + "<<<");
  return value;
};
const guService = {
  buscarAmbientes: () => {
    return api.get("/clientes/modulo/1");
  },
  loginGU: (token) => {
    return api.get(`/gu-api/login/${token}`);
  },
  validaToken: (token) => {
    return api.post("/gu-api/valida-token", { token });
  },

  logout: () => {
    localStorage.clear();
    if (window.location.pathname !== "/login") {
      window.location = process.env.REACT_APP_GU_FRONT + "/modulo/logout";
    }
  },

  redirectGU: () => {
    guService.logout()
    window.location = process.env.REACT_APP_GU_FRONT;
  },
  setLocalState,
  getLocalState,

  setLoginInfo: (data) => {
    setLocalState("token", data.access_token);
    setLocalState("nomeUsuario", data.nome_usuario);
    setLocalState("idUsuario", data.id_usuario);
    setLocalState("tipoUsuario", data.id_usuario_tipo);
    setLocalState("permissoes", data.permissoes);
    setLocalState("linkVendas", data.link_vendas);
    setLocalState("idInquilino", data.id_cliente);
    setLocalState("slug", data.slug);
    let retorno = data.modulos;
    let modulos = [];
    retorno.forEach((element) => {
      modulos.push(element.id_modulo);
    });
    setLocalState("modulos", JSON.stringify(modulos));
  },
};

export default guService;
